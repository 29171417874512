/* eslint-disable */

import React, {PureComponent as Component} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/message-container.css";
import MessageInputBar from "../components/AppComponents/MessageInputBar";
import {connect} from "react-redux";
import ConversationTItleBar from "../components/AppComponents/ConversationTItleBar";
import {fetchContactById} from "../actions/contactActions";
import ChatMessages from "../components/AppComponents/ChatMessages";
import ReactLoading from "react-loading";
import {postSms} from "../actions/messageActions";
import {fetchConversationForContact, updateConversation} from "../actions/conversationActions";
import ConversationScheduledMessagesComponent from "../components/AppComponents/ConversationScheduledMessagesComponent";
import {toast} from "react-toastify";
import {fetchDomains} from "../actions/redirectURLActions";


const stateToPropertyMapper = (state, props) => {
    return {
        conversations: state.conversations,
        selectedContact: state.contacts.items.find(contact=>contact.id==props.match.params.id),
        chat: state.chat,
        user:state.user,
        groups:state.groups.items,
        domains: state.redirectURL.domains,
    }
}

const propertyToDispatchMapper = (dispatch) => ({
    fetchContactById : (id) => 
        dispatch(fetchContactById(id)),
    sendMessage: (message,contactName) =>
        dispatch(postSms(message,contactName)),
    fetchConversationForContact:(contact_id)=>
        dispatch(fetchConversationForContact(contact_id)),
    fetchDomains:()=>{
        dispatch(fetchDomains());
    },
    updateConversation:(id, archived, starred)=>{
        dispatch(updateConversation(id, archived, starred))
    }
})

class MessageContainer extends Component{

    componentDidMount(){
        this.scrollToBottom();
        if(this.props.match.params.id!="new")
            this.props.fetchConversationForContact(this.props.match.params.id)
        if (this.props.fetchDomains) {
            this.props.fetchDomains();
        }
    }

    componentWillReceiveProps(props){
        if(props.match.params.id!=this.props.match.params.id){
            if(props.match.params.id!="new")
                props.fetchConversationForContact(props.selectedContact.id)
        }
        if(props.chat.postMessage.error){
            props.chat.postMessage.errorString.text()
                .then(res=>{
                    toast.error(JSON.parse(res).error)
                })
                .catch(err=>{
                    toast.error("Could not send message.")
                })
        }
    }

    
      componentDidUpdate() {
        this.scrollToBottom();
      }
    
      scrollToBottom() {
        this.el.scrollIntoView({ block:"end"});
      }

    render(){
        return(
            <div className="message-container">
                <div className="conversation-container ">
                    <ConversationTItleBar 
                        conversation={this.props.chat ? this.props.chat.conversation : null}
                        updateConversation={this.props.updateConversation}
                        heading={this.props.selectedContact?this.props.selectedContact.name:""}
                        displayImage={this.props.selectedContact?"https://slytext.com"+this.props.selectedContact.avatar:""}
                        isGroup={false}
                        id={this.props.selectedContact?this.props.selectedContact.id:""}
                        />

                    <span className={this.props.chat.messages.loading?"centered mt-4 pt-4":"hidden"}>
                        <br/>
                        <br/>
                        <ReactLoading type="spin"  
                            color={'#000000'} 
                            className={'centered'}
                            />
                    </span>

                    <div className={"chat-messages-container"}>
                        <ChatMessages messages={this.props.chat.messages.items.sort((function (a, b) {
                                          return new Date(a.timestamp) - new Date(b.timestamp)
                                        }))}
                                      name={this.props.selectedContact?this.props.selectedContact.name:""}
                                      groups={this.props.groups}/>

                        <div ref={el => { this.el = el; }} />
                    </div>


                    <div className={"send-box"}>
                        {this.props.chat.scheduledMessages.items.length > 0 ?
                            <div className="mb-1 conversation-scheduled-messages">
                                <ConversationScheduledMessagesComponent scheduledMessages={this.props.chat.scheduledMessages}/>
                            </div>
                            :
                            ""
                        }
                        <MessageInputBar conversation={this.props.conversation}
                                         sendMessage={this.props.sendMessage}
                                         remainingCredits={this.props.user.profile.subscription_quota+
                                                            this.props.user.profile.message_credit+
                                                            this.props.user.profile.slybroadcast_credits}
                                         contact_id={this.props.match.params.id}
                                         conversation_name={this.props.selectedContact?this.props.selectedContact.name:""}
                                         group_id={0}
                                         slyTextPhone={this.props.user.profile.slytext_phone}
                                         domains={this.props.domains}
                        />
                                         
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(MessageContainer)


