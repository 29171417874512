/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OtpInput from "react-otp-input";

function QrcodeModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ borderRadius: "1.5rem" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Set Up 2-Factor Authentication
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"text-center otp-modal"}>
                <img src={props.qrcodeSrc} alt='qrcode'></img>
                <div className="otp-form">
                    <label htmlFor="qrcode">Scan the QR using the Authenticator App.<br/>Enter the verification code
                        provided in the app:</label>
                    <OtpInput numInputs={6}
                              value={props.otpCode}
                              onChange={props.changeOtpCode}
                              inputType="number"
                              inputStyle="otp-input"
                              renderSeparator={<span> </span>}
                              renderInput={(props) => <input {...props} />}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "block" }}>
                <Row className={"pr-1"}>
                    <Col md={{ span: 3, offset: 6 }} className={"p-1"}>
                        <button onClick={(e) => {
                            e.preventDefault();
                            props.onSubmit(props.otpCode);
                        }}
                                className={"btn btn-primary col-12"}>Confirm
                        </button>
                    </Col>

                    <Col md={{ span: 3 }} className={"p-1"}>
                        <Button onClick={props.onHide}
                            variant={"secondary"}
                            className={"col-12"}>Cancel</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


QrcodeModal.propTypes = {
    onSubmit: PropTypes.func,
    changeOtpCode: PropTypes.func,
    onHide: PropTypes.func,
    qrcodeSrc: PropTypes.string,
    otpCode: PropTypes.string
}

export default QrcodeModal;
