/* eslint-disable */
import React from "react";
import {Elements} from "react-stripe-elements";
import AppsumoSignupComponent from "../components/AppComponents/AppsumoSignupComponent";


export default function AppsumoSignupContainer() {

    return (
        <Elements>
            <AppsumoSignupComponent />
        </Elements>
    )
}
