/* eslint-disable */
import {ADDING_TO_GROUP, CLOSE_ALL, SET_TEXT, SHOW_CONTACTS, SHOW_GROUPS} from "../actions/UIActions";

const initialState = {
    showContacts: false,
    showGroups: false,
    addingToGroup: false,
    text: ""
}

export default function UIReducer(state = initialState, action){
    switch(action.type){
        case SHOW_CONTACTS:
            return {
                ...state,
                showContacts: true,
                showGroups: false,
                addingToGroup: false
            }
        case SHOW_GROUPS:
            return {
                ...state,
                showContacts: true,
                showGroups: true,
                addingToGroup: false
            }
        case CLOSE_ALL:
            return {
                ...state,
                showContacts: false,
                showGroups: false,
                addingToGroup: false
            }
        case ADDING_TO_GROUP:
            return {
                ...state,
                showContacts: true,
                showGroups: !action.payload,
                addingToGroup: action.payload
            }
        case SET_TEXT:
            return {
                ...state,
                text: action.payload
            }
        default:
            return state
    }
}