/* eslint-disable */
import communicationsSpeechBubble from "../../assets/images/signup-slider/01.-communications-speech-bubble.png";
import communicationsCallSpeechSpeech from "../../assets/images/signup-slider/02.-communications-call-speech.png";
import d from "../../assets/images/signup-slider/d.png";
import React from "react";

export default function SignUpSlider() {
    return <div className="slider col-md-12 p-0">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className="slide w-slide">
                        <img
                            src={communicationsSpeechBubble} alt="" className="image-2"/>
                        <div className="div-block-4">
                            <div className="text-block-3"><strong>Enterprise &amp; Multimedia
                                Messaging</strong></div>
                            <div className="text-block-4">The ideal communication tool for sales and
                                marketing, CRM and customer service. Send videos, pictures and audio
                                files to one person or thousands of people.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="w-slide"><img src={communicationsCallSpeechSpeech} alt=""
                                                  className="image-5"/>
                        <div className="div-block-5">
                            <div className="text-block-5"><strong>Phone Numbers Local To Your
                                Customers</strong></div>
                            <div className="text-block-6">Select a slyText phone number from within your
                                preferred area code or text enable your own landline or VoIP number. Your
                                messages will be sent from this number and recipients are able to reply.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="w-slide"><img
                        src={d} alt=""
                        className="image-6"/>
                        <div className="div-block-6">
                            <div className="text-block-7"><strong>Easy Opt-Out</strong></div>
                            <div className="text-block-8">Users can stop text messages that they no
                                longer wish to receive by replying STOP or one of the unsubscribe keywords.
                                An automated response message will be sent to the customer
                                confirming that they have been opted out.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button"
               data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button"
               data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>


    </div>;
}
