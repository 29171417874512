/* eslint-disable */
import React, {Component} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthServices from '../../Services/AuthServices';
import NotificationHelpers from "../../helpers/NotificationHelpers";
import { Link } from "react-router-dom";
import { createToast } from "../../helpers/ToastIdHelper";
import TwoFaModal from './TwoFAModal';

const authService = AuthServices.getInstance();

export default class SignInFormComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            code: '',
            need2fa: false,
            showModal: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.toastId=104;
    }

    handleInputChange(e) {
        e.preventDefault();
        this.setState({
            [e.target.id]:e.target.value,
        });
    }


    handleFormSubmit = async (e) => {
        e.preventDefault();
        authService.getSession(this.state.email, this.state.password)
            .then(res => {
                if (res === true) {
                    window.location.href = "/app"
                } else {
                    this.setState({
                        showModal: true
                    })
                }
            })
            .catch(err => {
                if(err.status===500||err.status===401){
                    createToast(this.toastId,NotificationHelpers.INVALID_LOGIN_CRED,toast.TYPE.ERROR)
                }
            })
    }

    twoFaValidate = async () => {
        if (this.state.code.length !== 6) return
        try {
            const res = await authService.session2Fa(this.state.code)
            if (res === true) {
                window.location.href = "/app"
            }
        } catch (err) {
            if (err.status === 400) {
                createToast(this.toastId, NotificationHelpers.INVALID_OPT, toast.TYPE.ERROR)
            }
        }
    }

    changeCode = (e) => {
        this.setState({
            code: e
        })
    }

    cancel2Fa = () => {
        authService.signOut()
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <div>
                <TwoFaModal show={this.state.showModal} optCode={this.state.code} onSubmit={this.twoFaValidate} changeOptCode={this.changeCode} onHide={this.cancel2Fa} />
                <form>
                    <div className="form-group">
                        <label for="email">Email address</label>
                        <input type="email"
                               className="form-control"
                               id="email"
                               aria-describedby="emailHelp"
                               placeholder="Email address"
                               onChange={this.handleInputChange}
                               style={{borderRadius:"0.25rem"}}/>
                    </div>
                    <div className="form-group">
                        <label for="password">Password</label>
                        <input type="password"
                               className="form-control"
                               id="password"
                               placeholder="Password"
                               onChange={this.handleInputChange}
                               style={{borderRadius:"0.25rem"}}/>
                        <small><Link to="forgot-password" className="mb-4 mr-2 pull-right text-muted">Forgot password?</Link></small>
                    </div>
                    <button type="submit"
                            className="btn btn-primary col-12"
                            onClick={this.handleFormSubmit}
                            style={{borderRadius:"0.25rem"}}>Submit</button>
                </form>
            </div>
        )
    }
}
