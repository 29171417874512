import GroupServices from "../Services/GroupServices";
import {fetchConversationForGroup} from "./conversationActions";


const groupServices = GroupServices.getInstance();

/**********************************************************
 *                      Fetch all groups                  *
 **********************************************************/
export const FETCH_GROUPS_BEGIN = 'FETCH_GROUPS_BEGIN';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';

const fetchGroupBegin = () => ({
    type: FETCH_GROUPS_BEGIN,
});
const fetchGroupsSuccess = (groups) => ({
    type: FETCH_GROUPS_SUCCESS,
    payload: groups,
});

const fetchGroupsFailute = (error) => ({
type: FETCH_GROUPS_FAILURE,
payload: error,
});

export const fetchGroups = () => {
    return dispatch => {
        dispatch(fetchGroupBegin());
        groupServices.getGroups()
            .then(res => {
                dispatch(fetchGroupsSuccess(res));
            })
            .then(err => {
                dispatch(fetchGroupsFailute(err));
            })
    }
};

/**********************************************************
 *                     Fetch group by ID                  *
 ********************************************************** */
export const FETCH_GROUP_BY_ID_BEGIN = 'FETCH_GROUP_BY_ID_BEGIN';
export const FETCH_GROUP_BY_ID_SUCCESS = 'FETCH_GROUP_BY_ID_SUCCESS';
export const FETCH_GROUP_BY_ID_FAILURE = 'FETCH_GROUP_BY_ID_FAILURE';


const fetchGroupByIdBegin = (id) => ({
    type: FETCH_GROUP_BY_ID_BEGIN,
    payload:{
        id:id
    }
});
const fetchGroupByIdSuccess = (group) => ({
    type: FETCH_GROUP_BY_ID_SUCCESS,
    payload: group,
});

const fetchGroupByIdFailute = (error) => ({
    type: FETCH_GROUP_BY_ID_FAILURE,
    payload: error,
});

export const fetchGroupById = (id) => {
    return dispatch => {
        dispatch(fetchGroupByIdBegin());
        groupServices.getGroupById(id)
            .then(group => {
                dispatch(fetchGroupByIdSuccess(group));
                dispatch(fetchConversationForGroup(group.id));
            })
            .catch(err => {
                dispatch(fetchGroupByIdFailute(err));
            })
    }
};


/**********************************************************
 *                         Add a group                    *
 **********************************************************/

export const ADD_GROUP_BEGIN = "ADD_GROUP_BEGIN";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";

export const addGroupBegin=()=>({
    type:ADD_GROUP_BEGIN    
});

export const addGroupSuccess=(res)=>{
    return {
        type:ADD_GROUP_SUCCESS,
        payload:res
    }
};

const addGroupFailure=(err)=>({
    type:ADD_GROUP_FAILURE,
    payload:err
});

export const addGroup=(group)=>{
    return dispatch=>{
        dispatch(addGroupBegin());
        groupServices.addGroup(group)
            .then(res=>{
                dispatch(addGroupSuccess(res))
            })
            .catch(err=>dispatch(addGroupFailure(err)))
    }
};

/**********************************************************
 *                        Update a group                  *
 **********************************************************/

export const UPDATE_GROUP_BEGIN = "UPDATE_GROUP_BEGIN";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE";

const updateGroupBegin=()=>({
    type:UPDATE_GROUP_BEGIN    
});

export const updateGroupSuccess=(res)=>({
    type:UPDATE_GROUP_SUCCESS,
    payload:res
});

const updateGroupFailure=(err)=>({
    type:UPDATE_GROUP_FAILURE,
    payload:err
});

export const updateGroup=(id,group)=>{
    return dispatch=>{
        dispatch(updateGroupBegin());
        groupServices.updateGroup(id,group)
            .then(res=>updateGroupSuccess(res))
            .catch(err=>updateGroupFailure(err))
    }
};

/***********************************************************
 *                        Delete a group                   *
 ***********************************************************/

export const DELETE_GROUP_BEGIN = "DELETE_GROUP_BEGIN";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";

const deleteGroupBegin=()=>({
    type:DELETE_GROUP_BEGIN    
});

export const deleteGroupSuccess=(res)=>({
    type:DELETE_GROUP_SUCCESS,
    payload:res
});

const deleteGroupFailure=(err)=>({
    type:DELETE_GROUP_FAILURE,
    payload:err
});

export const deleteGroup=(id,group)=>{
    return dispatch=>{
        dispatch(deleteGroupBegin());
        groupServices.deleteGroup(id,group)
            .then(res=>deleteGroupSuccess(res))
            .catch(err=>deleteGroupFailure(err))
    }
};