/* eslint-disable */

import React from "react";
import NoticeModal from "./NoticeModal";


function WaitModal(props) {
    return <NoticeModal
        {...props}
        alertMessage={"Thank you for registering."}
        note={"Due to registration regulations surrounding text messaging, no text messages will be able to be sent until you receive an email informing you that the registration process is complete. Please note this can take up to 21 days. When your account registration and review has been completed, an email will be sent to you."}
        positiveButtonHead={"OK"}
    />

}

export default WaitModal;
