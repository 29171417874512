/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class AppsumoServices {
    static appsumoServices = null;

    static getInstance() {
        if (this.appsumoServices == null) {
            this.appsumoServices = new AppsumoServices();
        }
        return this.appsumoServices;
    }

    getAppsumoPlan(id) {
        return http._get("/appsumo/plan/" + id)
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    getAppsumoUser(uuid) {
        return http._get("/appsumo/user/" + uuid)
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    signUpAppsumoUser(state) {
        return http._post("/appsumo/user/" + state.uuid, state)
            .then(res => {
                if (res.sessionId) {
                    localStorage.setItem("joopzId", res.sessionId);
                    localStorage.setItem("userId", res.userId);
                } else {
                    throw res;
                }
                return true;
            })
            .catch(err => {
                throw err;
            });
    }

}