import {combineReducers} from "redux";
import {ConversationReducer} from "./reducers/ConversationReducer";
import ContactReducer from "./reducers/ContactReducer";
import GroupReducer from "./reducers/GroupReducer";
import ChatReducer from "./reducers/ChatReducer";
import BlockReducer from "./reducers/BlockReducer";
import UserReducer from "./reducers/UserReducer";
import AutoReplyReducer from "./reducers/AutoReplyReducer";
import ScheduledMessageReducer from "./reducers/ScheduledMessageReducer";
import PlanReducer from "./reducers/PlanReducer";
import SlybListReducer from "./reducers/SlybListReducer";
import RedirectURLReducer from "./reducers/RedirectURLReducer";
import UIReducer from "./reducers/UIReducer";

const SlytextApp = combineReducers({
     conversations: ConversationReducer,
     contacts: ContactReducer,
     groups: GroupReducer,
     chat: ChatReducer,
     blocks: BlockReducer,
     user: UserReducer,
     autoReply:AutoReplyReducer,
     scheduledMessages: ScheduledMessageReducer,
     plans:PlanReducer,
     slybLists:SlybListReducer,
     redirectURL: RedirectURLReducer,
     ui: UIReducer
});

export default SlytextApp;