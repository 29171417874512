/* eslint-disable */
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import AppsumoServices from "../../Services/AppsumoServices";
import NavbarFull from "./NavbarFull";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import "../../assets/style/signup-form.css";
import "../../assets/style/webflow.css";
import "../../assets/style/normalize.css";
import SignUpSlider from "./SignupSlider";
import {createToast} from "../../helpers/ToastIdHelper";
import TagManager from "react-gtm-module";

const appsumoServices = AppsumoServices.getInstance();


const tagManagerArgs = {
    gtmId: "G-HV48G305M9",
    dataLayer: {
        page: "signup-appsumo"
    },
};

const tagManagerArgsOnCreateAccount = {
    gtmId: "G-HV48G305M9",
    event: "createAccount",
    events: {
        createAccount: "createAccount"
    },
    dataLayer: {
        event: "createAccount",
        page: "signup-appsumo"
    },
};

const routeToApp = "/app";

class AppsumoSignupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: null,
            plan: null,
            password: "",
            passwordConfirm: "",
            sendingRequest: false
        };
        this.form = React.createRef();
        this.toastId = 106;
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        TagManager.initialize(tagManagerArgs);
        const url = window.location.href;
        const urlComponents = url.split("id=");
        if (urlComponents.length !== 2) {
            this.setState({error: "Invalid request"});
        }
        appsumoServices.getAppsumoUser(urlComponents[1])
            .then(res => {
                    this.setState({
                        uuid: urlComponents[1],
                        plan: res
                    });
                }
            )
            .catch(err => {
                this.setState({
                    plan: {error: err}
                });
            });
    }

    async handleFormSubmit(ev) {
        if (this.state.sendingRequest) {
            return;
        }
        this.setState({sendingRequest: true});
        if (!this.form.current.reportValidity()) {
            this.setState({sendingRequest: false});
            return;
        }

        if (this.state.password != this.state.passwordConfirm) {
            createToast(this.toastId, NotificationHelpers.PASSWORD_MISMATCH_ERROR, toast.TYPE.ERROR);
            this.setState({sendingRequest: false});
            return;
        }

        appsumoServices.signUpAppsumoUser(this.state)
            .then(res => {
                TagManager.initialize(tagManagerArgsOnCreateAccount);
                this.setState({sendingRequest: false});
                this.props.history.push(routeToApp);
            })
            .catch(err => {
                err.text()
                    .then(err => {
                        err = JSON.parse(err);
                        createToast(this.toastId, "Error: " + err.error, toast.TYPE.ERROR);
                        this.setState({sendingRequest: false});
                    });
            });

    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const plan = this.state.plan;
        if (!plan) {
            return <div/>;
        }
        const hasError = plan.error || (plan.has_name && plan.has_phone && plan.has_password);
        if (hasError) {
            this.props.history.push(routeToApp);
            return <div/>;
        }
        return (
            <div>
                <NavbarFull signedOut="true"/>
                {(plan && (!plan.has_name || !plan.has_phone || !plan.has_password)) &&
                <div className="container text-left container">
                    <div className={"row mt-4 pt-4"}>
                        <div className="col-md-5 offset-md-2 col-sm-10 offset-sm-1">
                            <div className="form">
                                <div className="text-block">Create Account</div>
                                <div className="my-3">
                                    <p><b>Your email: </b>{plan.email}</p>
                                    <p><b>Your plan: </b>${plan.appsumo_plan_price / 100} Lifetime
                                        Plan - {plan.appsumo_plan_messages} messages / month</p>
                                </div>
                                <hr/>
                                <p className={"text-muted"}><span className={"text-danger"}>*</span> Mandatory fields
                                </p>
                                <div className="form-block w-form">
                                    <form id="wf-form-Log-In" name="wf-form-Log-In" data-name="Log In"
                                          className="form-2"
                                          ref={this.form} onSubmit={e => e.preventDefault()}>
                                        {!plan.has_name && <div>
                                            <label htmlFor="name" className="field-label">Name<span
                                                className={"text-danger"}>*</span> </label>
                                            <input type="text"
                                                   className="text-field w-input"
                                                   autoFocus="true"
                                                   maxLength="256"
                                                   name="name"
                                                   data-name="Name"
                                                   placeholder="First &amp; last name"
                                                   id="name"
                                                   onChange={this.handleInputChange}
                                                   required/>
                                        </div>}

                                        {!plan.has_phone && <div>
                                            <label htmlFor="Phone-Number-3" className="field-label-2">Phone number<span
                                                className={"text-danger"}>*</span></label>
                                            <input type="tel"
                                                   className="text-field-2 w-input"
                                                   maxLength="256"
                                                   name="phone"
                                                   data-name="Phone Number"
                                                   placeholder="Phone number"
                                                   id="Phone-Number-3"
                                                   onChange={this.handleInputChange}
                                                   required/>
                                        </div>}

                                        {!plan.has_password && <div className="row">
                                            <div className={"col-6"}>
                                                <label htmlFor="Phone-number-3" className="field-label-2">Password<span
                                                    className={"text-danger"}>*</span></label>
                                                <input type="password"
                                                       className="text-field-2 w-input"
                                                       maxLength="256"
                                                       name="password"
                                                       data-name="Password"
                                                       placeholder="At least 8 characters"
                                                       id="Password"
                                                       onChange={this.handleInputChange}
                                                       autoComplete="new-password"
                                                       required/>
                                            </div>
                                            <div className={"col-6"}>
                                                <label htmlFor="Phone-number-3" className="field-label-2">Confirm
                                                    password<span className={"text-danger"}>*</span></label>
                                                <input type="password"
                                                       className="text-field-2 w-input"
                                                       maxLength="256"
                                                       name="passwordConfirm"
                                                       data-name="Confirm password"
                                                       placeholder="Enter password again"
                                                       id="Confirm-password"
                                                       onChange={this.handleInputChange}
                                                       autoComplete="new-password"
                                                       required/>
                                            </div>
                                        </div>}

                                        <button
                                            type="submit"
                                            className={this.state.sendingRequest ? "mt-3 button w-button bg-secondary" : "mt-3 button w-button"}
                                            onClick={this.handleFormSubmit}
                                            id={"submit-signup"}
                                            disabled={this.state.sendingRequest}>{this.state.sendingRequest ? "Please wait..." : "Submit"}
                                        </button>
                                    </form>

                                    <div className="w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"col-md-4 col-sm-0 d-none d-md-block"}>
                            <SignUpSlider/>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default withRouter(AppsumoSignupComponent);
