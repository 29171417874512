/* eslint-disable */

import {getHost} from "../helpers/url-helper";

const url = getHost()
export default class HttpClient {
    static myInstance = null;
    static getInstance() {
        if (HttpClient.myInstance == null) {
            HttpClient.myInstance =
                new HttpClient();
        }
        return this.myInstance;
    }

    _get=(path)=>{
        return fetch(url+path,{
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                    "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')):"",
                },
                method: 'GET'
            })
            .then(res => {
                if(res.status===400){
                    throw res;
                }
                if(res.status===403){
                    throw res;
                }
                if(res.status===405){
                    throw res;
                }
                if(res.status===500){
                    throw res;
                }
                return res.text()
                
            })
            .then(res => {
                if(res){
                    return JSON.parse(res)
                }else{
                    throw res;
                }
            })
            .catch(err =>  {
                throw err;
            })
    }

    _post=(path,body)=>{

        return fetch(url+path,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')):"",
                },
                body:JSON.stringify(body),
                method: 'POST'
            })
            .then(res => {
                if(res.status===403){
                    throw res;
                }
                if(res.status===402){
                    throw res;
                }
                if(res.status===401){
                    throw res;
                }
                if(res.status===500){
                    throw res;
                }
                if(res.status>=300){
                    throw res;
                }

                return res.text()})
            .then(res => {
                if(res)
                    return JSON.parse(res)
                else
                    return {}
            })
            .catch(err =>  {
                throw err;
            })
    }

    _put=(path,body)=>{
        return fetch(url+path,{
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                    "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')).id:"",
                },
                body:JSON.stringify(body),
                method: 'PUT'
            })
            .then(res => {
                if(res.status===403){
                    throw res;
                }
                if(res.status===500){
                    throw res;
                }
                if(res.status===400){
                    throw res;
                }
                if(res.status===422){
                    throw res;
                }
                return res.text()
            })
            .then(res => {
                if (res)
                    return JSON.parse(res)
                else
                    return {}
            })
            .catch(err =>  {
               throw err;
            })
        }

    _delete=(path)=>{
        return fetch(url+path,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')).id:"",
            },
            method: 'DELETE'
        })
        .then(res => {
            if(res.status===403){
                throw res;
            }
            if(res.status===500){
                throw res;
            }
            if(res.status===204){
                return res;
            }
            return res!=null?res.text():{}})
        .catch(err =>  {
            throw err;
        })
    }

    _postMedia=(path,body)=>{
        return fetch(url+path,{
            headers:{
                'Content-Type': 'application/octet-stream',
                'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')):"",
                },
                body:body,
                method: 'POST'
            })
            .then(res => {
                if(res.status===403){
                    throw res;
                }
                if(res.status===500){
                    throw res;
                }
                if(res.status===400){
                    throw res;
                }
                return res.text()})
            .then(res => JSON.parse(res))
            .catch(err =>  {
                throw err;
            })
    }
}