/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OtpInput from "react-otp-input";

function TwoFaModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ borderRadius: "1.5rem" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    One-Time Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"text-center otp-modal"}>
                <div className="otp-form">
                    <OtpInput numInputs={6}
                        value={props.optCode}
                        onChange={props.changeOptCode}
                        inputType='number'
                        inputStyle='otp-input'
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "block" }}>
                <Row className={"pr-1"}>
                    <Col md={{ span: 3, offset: 6 }} className={"p-1"}>
                        <button onClick={(e) => {
                            e.preventDefault();
                            props.onSubmit(props.optCode);
                        }}
                                className={"btn btn-primary col-12"}>Confirm
                        </button>
                    </Col>

                    <Col md={{ span: 3 }} className={"p-1"}>
                        <Button onClick={props.onHide}
                            variant={"secondary"}
                            className={"col-12"}>Cancel</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


TwoFaModal.propTypes = {
    onSubmit: PropTypes.func,
    changeOptCode: PropTypes.func,
    onHide: PropTypes.func,
    optCode: PropTypes.string
}

export default TwoFaModal;
