/* eslint-disable */
import "bootstrap/dist/css/bootstrap.css";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";
import {AutoSizer, List} from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import ConversationComponent from "./ConversationComponent";
import Switch from "react-switch";

import React, {Component} from "react";

export default class ConversationList extends Component {
    constructor(props){
        super(props);
        this.state = {
            inr: 1,
            conversationFilterString: "",
            conversations: this.sortConversations(this.props.conversations.items),
            showOnlyReply: false
        }
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    sortConversations(json) {
        const key = "timestamp";
        return json.sort(function (a, b) {
            if (a.starred - b.starred !== 0) {
                return b.starred - a.starred;
            } else
                if (a[key] < b[key]) {
                return 1;
            } else if (a[key] > b[key]) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    componentWillReceiveProps(props){
        if(props!=this.props){
            this.setState({
                inr: this.state.inr+1,
                conversations: this.sortConversations(props.conversations.items)
            }, () => {
                if (!this.state.conversations.length) return
                const filterStatus = localStorage.getItem('filter_reply')
                if (filterStatus) {
                    this.handleShowChange(JSON.parse(filterStatus))
                }
            })
        }
    }

    componentDidMount(){
        this.props.fetchAllConversations();
    }

    handleShowChange = (checked) => {
        if (checked) {
            this.setState({
                showOnlyReply: checked,
                conversations: this.state.conversations.filter(z => z.reply_count > 0)
            })
        }
        else {
            this.setState({
                showOnlyReply: checked,
                conversations: this.props.conversations.items.filter(z => z.name.toLowerCase().includes(this.state.conversationFilterString))
            })
        }
        localStorage.setItem('filter_reply', checked)
    };

    handleFilterChange(e) {
        if (this.state.showOnlyReply) {
            this.setState({
                conversationFilterString: e.target.value.toLowerCase(),
                conversations: this.props.conversations.items.filter(z => z.name.toLowerCase().includes(e.target.value) && z.reply_count > 0)
            })
        } else {
            this.setState({
                conversationFilterString: e.target.value.toLowerCase(),
                conversations: this.props.conversations.items.filter(z => z.name.toLowerCase().includes(e.target.value))
            })
        }
    }


    render() {
        let props = this.props;
        return (
            <div className="p-0 h-100">
                <div className="container my-2 bg-muted d-flex align-items-center">
                    <input className="form-control rounded empty"
                        placeholder="&#xF002; Search in Conversations"
                        onChange={this.handleFilterChange}>
                    </input>

                    {/* add switch  */}
                    <div className="d-flex flex-column justify-content-center align-items-center pl-2">
                        <span style={{
                            letterSpacing: 0,
                            whiteSpace: "nowrap",
                            fontSize: 12
                        }}>
                            Filter Replies
                        </span>
                        <Switch onChange={this.handleShowChange} checked={this.state.showOnlyReply} height={15}
                                width={30}/>
                    </div>
                </div>
                <div className={props.conversations.loading && props.conversations.items.length === 0 ? "centered " : "invisible hidden"}>
                    <ReactLoading type="spin"
                        color={'#000000'}
                        className={'centered'}
                    />
                </div>
                <div id="virtualized" style={{ flexGrow: 1, height: 'calc(100% - 95px)' }}>
                    {props.conversations.loading || this.state.conversations.length > 0 ?
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    rowCount={this.state.conversations.length}
                                    rowHeight={78}
                                    width={width}
                                    rowRenderer={this.Row}
                                    overscanRowCount={5}
                                    style={{ textDecoration: "none" }}
                                >
                                </List>
                            )}
                        </AutoSizer>
                        :
                        <div className={"text-muted col-10 offset-1 text-left"}>
                            <br />
                            Click on <i className={"fa fa-plus"} /> in the top
                            to create <Link to={"/contacts/new/edit"}>contact</Link> or a <Link to={"/groups/new/edit"}> group</Link>.
                            <br /><br />
                            Click on <i className={"fas fa-user"} /> in the top
                            to start conversation with a single contact.
                            <br /><br />
                            Click on <i className={"fas fa-users"} /> in the top
                            to broadcast a message to a group.

                        </div>
                    }
                </div>
                {/* {props.conversations.items.map(z => {
                if(z.name.toLowerCase().includes(this.state.conversationFilterString)){
                    return (

                    )
                }
            }

            )} */}
            </div>
        )
    }

    Row = ({ index, style }) => {
        return <Link to={this.state.conversations[index].contact_id === 0 ?
            "/groups/" + this.state.conversations[index].group_id + "/conversation/" + this.state.conversations[index].id
            :
            "/contacts/" + this.state.conversations[index].contact_id + "/conversation/" + this.state.conversations[index].id}
            key={this.state.conversations[index].id}
            style={style}
            className="no-decoration">
            <ConversationComponent conversation={this.state.conversations[index]}
                target={this.state.conversations[index].group_id != 0 ?
                    this.props.groups.items.find(group =>
                        group.id == this.state.conversations[index].group_id)
                    :
                    this.props.contacts.items.find(contact =>
                        contact.id == this.state.conversations[index].contact_id)} />
        </Link>
    }
}
