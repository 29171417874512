export const SHOW_CONTACTS = "SHOW_CONTACTS";
export const SHOW_GROUPS = "SHOW_GROUPS";
export const CLOSE_ALL = "CLOSE_ALL";
export const ADDING_TO_GROUP = "ADDING_TO_GROUP";
export const SET_TEXT = "SET_TEXT";

export const showContacts = () => ({
    type: SHOW_CONTACTS
});

export const showGroups = () => ({
    type: SHOW_GROUPS
});

export const closeAll = () => ({
    type: CLOSE_ALL
});

export const addToGroup = (toggle) => ({
    type: ADDING_TO_GROUP,
    payload: toggle
});

export const setText = (text) => ({
    type: SET_TEXT,
    payload: text
});