/* eslint-disable */
import React, {useState} from "react";
import OptoutlistItem from "./OptoutlistItem";
import {getDate} from "../../helpers/FormattingHelper";
import {CSVLink} from "react-csv";
import CSVReader from "react-csv-reader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function OptoutList(props) {
    const [popupOpened, setPopupOpened] = useState(false);
    
    return (
        <div className="row">
            <div className="col-md-10 col-sm-12 mt-4 offset-md-1">
                <CSVLink data={props.blocks.map(b=>({
                    Phone:b.Phone,
                    Time:getDate(b.CreatedAt),
                }))}
                         filename={"slytextOptoutList.csv"}
                         headers={[
                             { label: "Phone number", key: "Phone" },
                             { label: "Opt-out date", key: "Time" },
                         ]}>
                    <button className="btn btn-secondary btn-sm text-white mt-2">Download CSV</button>
                </CSVLink>
                <button className={"btn btn-secondary btn-sm text-white ml-2 mt-2"}
                        onClick={setPopupOpened}>Upload CSV</button>
                <hr/>
                <div className={"row text-left"}>
                    <div className="col-6"><h5>Phone number </h5> <span className={"font-italic text-muted"}>{props.blocks.length} Number(s)</span></div>
                    <div className="col-6"><h5>Opt-out date</h5></div>
                </div>
                    {props.blocks.sort((a,b)=>new Date(b.CreatedAt)-new Date(a.CreatedAt)).map((block,index) => {

                        return <OptoutlistItem block={block} id = {index+1} key={block.ID}/>
                    })}
                <span className={"mt-1"}><hr/></span>
            </div>
            <UploadModal
                show={popupOpened}
                onHide={() => setPopupOpened(false)}
                csvLoaded={props.setOptOutList}
                onConfirm={() => {
                    setPopupOpened(false)
                    props.uploadOptOutList()
                }}
                optOutList={props.optOutList}
            />
        </div>
    )
}

const UploadModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Opt-out List
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CSVReader
                    cssClass="csv-reader-input"
                    onFileLoaded={data => {
                        props.csvLoaded(data.map(d => d[0]))
                    }}
                    parserOptions={{
                        header: false,
                        dynamicTyping: false,
                        skipEmptyLines: true,
                    }}
                />
                <br/>
                {props.optOutList.length > 0 && <h6>{props.optOutList.length} numbers found in this file.</h6>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onConfirm}>Upload</Button>
                <Button variant="danger" onClick={props.onHide}>No</Button>
            </Modal.Footer>
        </Modal>
    );
};