
import React, {PureComponent} from 'react';
import { Modal} from 'react-bootstrap';
import SlybroadcastListServices from "../../Services/SlybroadcastListServices";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import {createToast, SLYB_GROUP_PHONES} from "../../helpers/ToastIdHelper";
import {toast} from "react-toastify";
import SlybImportSuccess from "./SlybImportSuccessModal";


class SlybListModal extends PureComponent{
    constructor(props){
        super(props);
        this.state={
            list:{
                id:-1,
                name:"",
                phones:[],
                is_imported:false,
            },
            showSuccess:false,
            groups:[]
        }
    }

    componentWillReceiveProps(props) {
        if(!props.list){
            return;
        }
        SlybroadcastListServices.getListPhones(props.list.c_listid)
            .then(res => {
                this.setState({
                    list:{
                        id:props.list.c_listid,
                        name:props.list.list_name,
                        phones:res,
                        is_imported: props.isImported(parseInt(props.list.c_listid)),
                    }
                })
            })
            .catch(()=>{
                createToast(SLYB_GROUP_PHONES,NotificationHelpers.ERROR_SLYB_PHONE,toast.TYPE.ERROR)
            })

    }

    handleImportList = (e) => {
      e.preventDefault();
      SlybroadcastListServices.importList(this.state.list.id,this.state.list.name)
          .then(res => {
              createToast(SLYB_GROUP_PHONES,NotificationHelpers.listImported(res.groups.length),toast.TYPE.SUCCESS);
              this.setState({
                  groups:res.groups,
                  showSuccess:true
              })
          })
          .catch(err=>{
              err.text().then(res=>{
                  res = JSON.parse(res);
                  createToast(SLYB_GROUP_PHONES,
                      NotificationHelpers.listImportedWithError(res.groups.length,res.error),
                      toast.TYPE.ERROR)
              }).catch(()=>{

              })
          })
          .then(()=>{
              this.props.fetchGroups()
          })
    };

    render() {
            return (
                <Modal
                    {...this.props}
                    size={this.state.showSuccess?"sm":"lg"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={{borderRadius: "1.5rem"}}>

                    {/* Modal header */}
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            slybroadcast List - {this.state.list.name}
                        </Modal.Title>
                    </Modal.Header>

                    {/* Modal body */}
                    <Modal.Body>
                        <div className="row text-center px-4 pb-2">
                        <span className={"ml-2 text-secondary"}>
                     Note: Lists larger than 1,000 phone numbers will be imported in multiple lists.
                 </span>
                            {this.state.list.is_imported?
                                <div className={"col-12 text-left p-0 mb-2"}>
                                List aready imported to slyText.
                                </div>
                                :
                                <div className={"col-12 text-left p-0 mb-2"}>
                                    <button className={"btn btn-secondary"}
                                         onClick={this.handleImportList}>Import</button>
                                    {this.state.list.phones.length>1?
                                        <span className={"ml-2 text-secondary"}>

                                 </span>
                                        :""}
                                </div>
                            }


                            <h4 className="m-0">Phone Numbers:</h4>
                            {!this.state.showSuccess?
                                <ul className="list-group w-100">
                                    {this.state.list.phones.map(p=>
                                        <li className="list-group-item w-100 text-left">
                                            {p.phone_number} {p.name?" - "+p.name:""}
                                        </li>
                                    )}
                                </ul>
                                :""
                            }

                        </div>
                        <SlybImportSuccess
                            show={this.state.showSuccess}
                            onHide={()=>{this.setState({showSuccess:false})}}
                            groups={this.state.groups}/>
                    </Modal.Body>
                </Modal>
            );
        }

}

export default SlybListModal;
