/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class PhoneServices{
    static phoneServices = null;
    static getInstance(){
        if(this.phoneServices==null){
            this.phoneServices=new PhoneServices();
        }
        return this.phoneServices;
    }

    getIndustries() {
        return http._get("/industries")
            .then(res => res)
            .catch(err => {throw err})
    }
    
    checkZipCode(zip_code) {
        return http._get("/zipcode/" + zip_code)
            .then(res => res)
            .catch(err => {throw err})
    }
    
    completeProfile(profile) {
        return http._post("/complete", profile)
            .then(res => res)
            .catch(err => {throw err})
    }

    getNumbers(prefix){
        return http._get("/numbers" + (prefix ? "?prefix="+prefix : ""))
                .then(res => res)
                .catch(err => {throw err})
    }

    purchaseNumber(phone){
        return http._post("/numbers/"+phone)
            .then(res => res)
            .catch(err => {throw err})
    }
    
    bulkCompanionNumber(phone) {
        return http._post("/bulk/numbers/"+phone)
            .then(res => res)
            .catch(err => {throw err})
    }
    
    getCompanionNumbers() {
        return http._get("/bulk/numbers")
            .then(res => res)
            .catch(err => {throw err})
    }
}