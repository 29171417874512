/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class AuthServices{
    static authServices = null;
    static getInstance(){
        if(this.authServices==null){
            this.authService=new AuthServices();
        }
        return this.authService;
    }

    getSession(username, password) {
        return http._post("/session", {
            username: username,
            password: password,
        }).then(res => {
            if (res.sessionId) {
                localStorage.setItem('enable2FA', res.requires_two_factor)
                localStorage.setItem("joopzId", res.sessionId);
                localStorage.setItem("userId", res.userId);
                if (res.requires_two_factor === false) {
                    return true
                }
                else {
                    return res
                }
            } else {
                throw res
            }

        }).catch(err => {
            throw err;
        })
    }

    session2Fa(code) {
        return http._post('/session-two-factor', {
            otp: code
        }).then(res => {
            return true
        }).catch(err => {
            throw err;
        })
    }

    getP2pUsers() {
        return http._get('/p2p_users').then(res => {
            return res
        }).catch(err => {
            throw err;
        })
    }

    p2pSession(email) {
        return http._post('/p2p_session', {
            email: email
        }).then(res => {
            if (res.sessionId) {
                localStorage.setItem("joopzId", res.sessionId);
                localStorage.setItem("userId", res.userId);
            }
            return true
        }).catch(err => {
            throw err;
        })
    }

    getSessionSlybroadcast(username,password){
        return http._post("/session/slybroadcast",{
            username: username,
            password: password,
        }).then(res => {
            if(res.sessionId){
                localStorage.setItem("joopzId",res.sessionId);
                localStorage.setItem("userId",res.userId);
            }else{
                throw res
            }
            return true;
        }).catch(err => {
            throw err;
        })
    }

    storedSession(){
        return localStorage.getItem("joopzId");
    }

    get2FaQrcode() {
        return http._post('/set-up-two-factor-authentication', '').then(res => {
            if (res.secret) {
                return res.secret
            }
            else {
                throw res
            }
        })
    }

    enable2FaValication(code) {
        return http._post('/enable-two-factor-authentication', {
            otp: code
        }).then(res => {
            return true
        }).catch(err => {
            throw err;
        })
    }

    loggingInAgent() {
        return localStorage.getItem("loggingInAgent") === "true" || localStorage.getItem("loggingInAgent") == true;
    }

    getSearchParams() {
        var searchParams = new URLSearchParams(window.location.search);
        return searchParams;
    }

    isLoggedIn(){
        var searchParams = this.getSearchParams();
        var token = searchParams.get("token");
        if (this.loggingInAgent()) return false;
        if (token && storedResponderToken() !== token) {
            localStorage.setItem("loggingInAgent", "true");
            this.verifyAgentResponder(searchParams.get("campaign"), token).then(res => {
                if (res.sessionId) {
                    localStorage.setItem("joopzId", res.sessionId);
                    localStorage.setItem("userId", res.userId);
                    localStorage.setItem("responderToken", token);
                    localStorage.setItem("loggingInAgent","false");
                }else{
                    throw res
                }
                this.getProfile()
                    .then(res=>{
                        window.location.href="/app";
                    })
                    .catch(err=>{
                        console.log("1 Signing out because: ")
                        console.error(err);
                        this.signOut();
                        return false;
                    });
            }).catch(err => console.error(err));
        }
        if (this.storedSession() != null &&
            localStorage.getItem("userId") != null){
            return this.getProfile()
                .then(res=>{return true})
                .catch(err=>{
                    console.log("Signing out because: ");
                    console.error(err);
                    this.signOut();
                    return false;
                })
        }
    }

    signOut(){
        http._delete("/session")
            .catch(err => {
                console.error(err);
            })
            .finally(res=>{
                localStorage.clear();
                window.location.reload();
            })
    }


    getProfile(){
        return http._get("/slytext-profile")
            .then(res => {
                localStorage.setItem("redirectUser",res.redirect_user);
                return Promise.resolve(res);
            }).catch(err => {throw err});
    }

    updateProfile(profile){
        return http._put("/profile",profile)
            .then(res=>res)
            .catch(err=>{throw err})
    }

    sendForgotPasswordLink(email){
        return http._post("/forgot-password/"+email,{})
            .then(res => res)
            .catch(err => {throw err})
    }

    resetPassword(token,password){
        return http._post("/reset-password/"+token,{password:password})
            .then(res => res)
            .catch(err => {throw err})
    }

    getPhoneVerificationCode(){
        return http._post("/confirm-phone",{})
            .then(res => res)
            .catch(err => {throw err})

    }

    verifyPhoneByToken(token){
        return http._post("/confirm-phone/"+token,{})
            .then(res => res)
            .catch(err=>{throw err})
    }

    verifyAgentResponder(campaignID, token) {
        return http._post("/campaigns/"+campaignID+"/confirm-responder/",{responder_token: token})
                   .then(res => res)
                   .catch(err=>{throw err})
    }

    cancelAccount(){
        return http._post("/cancel", {}).then(res=> res).catch(err=> {
            throw err
        })
    }
}

export function storedResponderToken() {
    return localStorage.getItem("responderToken");
}
