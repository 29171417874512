/* eslint-disable */
import {connect} from "react-redux";

import Main from "../components/Main";
import {fetchContacts} from "../actions/contactActions";
import {fetchGroups} from "../actions/groupActions";
import {fetchProfile} from "../actions/UserActions";
import {fetchConversationByID, fetchConversations, newMessageArrived} from "../actions/conversationActions";
import {closeAll, showContacts, showGroups} from "../actions/UIActions";


const stateToPropertyMapper = (state) => {
    return {
        user:state.user,
        conversation:state.chat.conversation,
        contacts:state.contacts,
        ui: state.ui
    }
}

const propertyToDispatchMapper = (dispatch,state) => ({

    fetchContacts: () => {
        dispatch(fetchContacts())
    },
    fetchGroups: () => {
        dispatch(fetchGroups())
    },
    fetchProfile: () => {
        dispatch(fetchProfile())
    },
    fetchNewMessages: () => {
        dispatch(fetchConversations())
    },
    fetchConversationById:(id)=>{
        dispatch(fetchConversationByID(id))
    },
    newMessage:(id,message)=>{
        dispatch(newMessageArrived(id,message))
    },
    showContacts: () => {
        dispatch(showContacts())
    },
    showGroups: () => {
        dispatch(showGroups())
    },
    closeAll: () => {
        dispatch(closeAll())
    }
})

const MainContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(Main)

export default (MainContainer);
