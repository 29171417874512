/* eslint-disable */
import React, {Component} from "react";
import {connect} from "react-redux";
import {createBlockSuccess, fetchBlocks} from "../actions/blockActions";
import "../assets/style/optout.css";


import OptoutList from "../components/AppComponents/OptoutList";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import OptoutListModal from "../components/AppComponents/OptoutListAddModal";
import {createToast} from "../helpers/ToastIdHelper";
import {toast} from "react-toastify";
import ContactServices from "../Services/ContactServices";

const stateToPropertyMapper = (state, props) => {
    return {
        blocks: state.blocks,
    }
}

const propertyToDispatchMapper = (dispatch) => ({
    fetchOptOutList : () => 
        dispatch(fetchBlocks()),
    createBlockSuccess : (block) =>
        dispatch(createBlockSuccess(block))
})

class OptoutlistContainer extends Component {
    constructor(props){
        super(props)
        this.state={
            blockNumber:'',
            blockConfirmationShow:false,
            optOutList: []
        }
        this.toastId=108;
    }
    componentDidMount(){
        this.props.fetchOptOutList()
    }

    handleChange=(e)=>{
        this.setState({
            blockNumber:(e.target.value)
        })
    };


    showBlockCreateModal=(e)=>{
        e.preventDefault();
        if(this.state.blockNumber.length<10){
            createToast(this.toastId,"Enter a valid phone number.",toast.TYPE.ERROR);
            return
        }
        this.setState({
            blockConfirmationShow:true
        });
    };

    handleCreateBlock=(e)=>{
        const contactServices = ContactServices.getInstance();
        contactServices.createBlock(0,this.state.blockNumber)
            .then(res => {
                createToast(this.toastId,res.contact_phone+" is added to the opt-out list.",toast.TYPE.SUCCESS)
                this.props.createBlockSuccess({
                    Phone:res.contact_phone,
                    CreatedAt: Date.now()
                })
                this.setState({
                    blockConfirmationShow:false,
                    blockNumber:'',
                })
            })
            .catch(err=>{
                console.log(err)
                err.text().then(res=>{
                    err=JSON.parse(res)
                    if(err.error.includes("canonicalize")){
                        err.error="Invalid phone number."
                    }
                    createToast(this.toastId,err.error,toast.TYPE.ERROR)
                })
                this.setState({
                    blockConfirmationShow:false,
                })
            })
    }

    uploadOptOutList = () => {
        ContactServices.createBlocks(this.state.optOutList).then(() => {
            this.setState({optOutList: []});
            toast.success("Opt-out list uploaded successfully!");
            this.props.fetchOptOutList();
        }).catch(() => {
            toast.error("Could not upload opt-out list.")
        })
    }
    
    render() {
        return (
            <div className="message-container">
                <div className="conversation-container">
                    {/* Titlebar start */}
                    <div className="conversation-title-container text-light shadow">
                        <div className="row p-2 px-4">
                                <div className="p-2  col-8 text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            Opt-out list
                                        </span>
                                </div>
                                <div className={"col-4 mt-1"}>
                                    <Form className={"row"}>
                                        <Col md={{span:10}}>
                                            <input className={"form-control"}
                                                   type={"text"}
                                                   placeholder={"Enter phone number..."}
                                                   value={this.state.blockNumber}
                                                   name={"blockNumber"}
                                                   onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col md={{span:1}} className={"ml-0 pl-0"}>
                                            <Button variant={"danger"}
                                                    className={"round"}
                                                    onClick={this.showBlockCreateModal}
                                                    title="Add number">
                                                <i className="fas fa-plus"/>
                                            </Button>
                                        </Col>
                                        <OptoutListModal show={this.state.blockConfirmationShow}
                                                         onHide={() => {
                                                             this.setState({
                                                                 blockConfirmationShow:false,
                                                                 blockNumber:'',
                                                             })
                                                         }}
                                                         phoneNumber={this.state.blockNumber}
                                                         onSubmit={this.handleCreateBlock}/>
                                    </Form>
                                </div>
                        </div>
                    </div>
                    {/* Titlebar end */}
                        <div className={"optout-list-container"}>
                            <OptoutList 
                                blocks={this.props.blocks.items.sort((a,b)=> (new Date(a)-new Date(b)))}
                                optOutList={this.state.optOutList}
                                setOptOutList={(optOutList) => this.setState({optOutList})}
                                uploadOptOutList={this.uploadOptOutList}
                            />
                        </div>
                    </div>
            </div>
        )
    }
}


export default connect(
    stateToPropertyMapper,
    propertyToDispatchMapper
)(OptoutlistContainer)