/* eslint-disable */
import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Styles/message-container.css";
import MessageInputBar from "../components/AppComponents/MessageInputBar";
import {connect} from "react-redux";
import ConversationTItleBar from "../components/AppComponents/ConversationTItleBar";
import {fetchGroupById} from "../actions/groupActions";
import ChatMessages from "../components/AppComponents/ChatMessages";

import ReactLoading from "react-loading";
import {postSms} from "../actions/messageActions";
import ConversationScheduledMessagesComponent from "../components/AppComponents/ConversationScheduledMessagesComponent";
import {fetchConversationForGroup, updateConversation} from "../actions/conversationActions";
import {toast} from "react-toastify";
import {fetchDomains} from "../actions/redirectURLActions";


const stateToPropertyMapper = (state, props) => {
    return {
        conversations: state.conversations,
        contacts: state.contacts,
        selectedGroup: state.groups.items.find(group=>group.id==props.match.params.id),
        chat: state.chat,
        user: state.user,
        domains: state.redirectURL.domains,
    }
};

const propertyToDispatchMapper = (dispatch) => ({
    fetchGroupById : (id) => 
        dispatch(fetchGroupById(id)),
    sendMessage: (message,conversation_name) =>
        dispatch(postSms(message,conversation_name)),
    fetchConversationForGroup:(group_id)=>
        dispatch(fetchConversationForGroup(group_id)),
    fetchDomains:()=>{
        dispatch(fetchDomains());
    },
    updateConversation:(id, archived, starred)=>{
        dispatch(updateConversation(id, archived, starred))
    }
});

class MessageContainer extends Component{

    componentDidMount(){
        this.scrollToBottom();
        if(this.props.match.params.id!=="new")
            this.props.fetchConversationForGroup(this.props.match.params.id)
        if (this.props.fetchDomains) {
            this.props.fetchDomains();
        }
    }

    componentWillReceiveProps(props){
        if(props.match.params.id!=this.props.match.params.id){
            if(props.match.params.id!=="new")
                props.fetchConversationForGroup(props.selectedGroup.id)
        }
        if(props.chat.postMessage.error){
            props.chat.postMessage.errorString.text()
                .then(res=>{
                    toast.error(JSON.parse(res).error)
                })
                .catch(err=>{
                    toast.error("Could not send message.")
                })
        }
    }

    componentDidUpdate() {
        this.scrollToBottom();
      }
    
      scrollToBottom() {
        this.el.scrollIntoView({ block:"end"});
      }
    

    render(){
        return(
            <div className="message-container">
                <div className="conversation-container">
                    <ConversationTItleBar
                        conversation={this.props.chat ? this.props.chat.conversation : null}
                        updateConversation={this.props.updateConversation}
                        heading={this.props.selectedGroup?this.props.selectedGroup.name:""}
                        displayImage={this.props.selectedGroup?"https://slytext.com/"+this.props.selectedGroup.avatar:"https://cdn3.vectorstock.com/i/1000x1000/86/92/group-of-people-icon-white-vector-15998692.jpg"}
                        isGroup={true}
                        id={this.props.selectedGroup?this.props.selectedGroup.id:""}/>
                    <span className={this.props.chat.messages.loading?"centered mt-4 pt-4":"hidden"}>
                        <br/>
                        <br/>
                        <ReactLoading type="spin"  
                            color={'#000000'} 
                            className={'centered'}
                            />
                    </span>

                    <div className={"chat-messages-container"}>
                        <ChatMessages messages={this.props.chat.messages.items.sort((function (a, b) {
                                return new Date(a.timestamp) - new Date(b.timestamp)
                                }))}
                                name={this.props.selectedGroup?this.props.selectedGroup.name:""}
                                groupCount={this.props.selectedGroup&&this.props.selectedGroup.members?this.props.selectedGroup.members.length:0}/>


                        <div ref={el => { this.el = el; }} />

                    </div>
                    <div className={"send-box"}>

                        <ConversationScheduledMessagesComponent scheduledMessages={this.props.chat.scheduledMessages} fromConversation={true}/>
                        <MessageInputBar conversation={this.props.conversation}
                                         slyTextPhone={this.props.user.profile.slytext_phone}
                                         remainingCredits={this.props.user.profile.subscription_quota+
                                                             this.props.user.profile.message_credit+
                                                             this.props.user.profile.slybroadcast_credits}
                                         sendMessage={this.props.sendMessage}
                                         group_id={parseInt(this.props.match.params.id)}
                                         conversation_name={this.props.selectedGroup?this.props.selectedGroup.name:""}
                                         contact_id={0}
                                         keywords={this.props.selectedGroup?this.props.selectedGroup.keywords:undefined}
                                         domains={this.props.domains}

                        />
                        </div>
                    </div>
                
            </div>
        )
    }
}

export default connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(MessageContainer)


