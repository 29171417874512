/* eslint-disable */
import React, {Component} from "react";
import {Row} from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import PhoneServices from "../../Services/PhoneNumberServices";
import {toast} from "react-toastify";
import * as Promise from "bluebird";
import {CSVLink} from "react-csv";
import {formatTime} from "../../helpers/FormattingHelper";

const numberServices = PhoneServices.getInstance();

export default class BulkActivation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberInput: "",
            numberToActivate: 0,
            numberActivated: 0,
            batch: [],
            activatedNumbers: [],
            loading: false,
            error: false
        };
    }

    componentDidMount() {
        this.getActivatedNumbers();
    }

    getActivatedNumbers() {
        numberServices.getCompanionNumbers()
            .then(res => {
                this.setState({
                    activatedNumbers: res
                });
            })
            .catch(err => {
                console.error(err);
                toast.error("Unable to get activated phone numbers.");
            });
    }

    resetState() {
        this.setState({
            numberInput: "",
            numberToActivate: 0,
            numberActivated: 0,
            batch: [],
            loading: false,
            error: false
        });
    }

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleActivate = () => {
        this.resetState();
        const numberInput = parseInt(this.state.numberInput);
        if (!numberInput || numberInput > 1000) {
            toast.error("You can only activate 1 to 1000 numbers at once.");
            return;
        }
        this.setState({
            numberToActivate: numberInput,
            loading: true
        });
        numberServices.getNumbers()
            .then(res => {
                this.setState({
                    batch: res
                }, this.bulkProcessing);
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    loading: false,
                    error: true
                });
                toast.error("Unable to get inventory.");
            });
    };

    bulkProcessing() {
        return Promise.mapSeries(this.state.batch, number => {
            if (this.state.numberActivated >= this.state.numberToActivate) {
                return Promise.reject("done");
            }
            return numberServices.bulkCompanionNumber(number)
                .then(() => {
                    return new Promise(resolve => {
                        this.setState({
                            numberActivated: this.state.numberActivated + 1,
                        }, () => {
                            resolve(true);
                        });
                    });
                })
                .catch(Promise.resolve(false));
        })
            .then(results => {
                const successes = results.filter(res => res).length;
                // all failed, stop
                if (!successes) {
                    return Promise.reject("failed");
                }
                this.getActivatedNumbers();
                return numberServices.getNumbers()
                    .then(res => {
                        this.setState({
                            batch: res
                        }, this.bulkProcessing);
                    });
            })
            .catch(err => {
                this.getActivatedNumbers();
                if (err === "failed") {
                    this.setState({
                        loading: false,
                        error: true
                    });
                    toast.error("Failed to activate numbers.");
                }
                if (err === "done") {
                    this.setState({
                        numberInput: "",
                        batch: [],
                        loading: false
                    });
                }
            });
    }

    render() {
        return (
            <div className="message-container">
                <div className="conversation-container">

                    {/* Titlebar start */}
                    <div className="conversation-title-container text-light shadow">
                        <div className="row p-2">
                            <div className="col-12">
                                <div className="p-2  text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            Bulk Activation
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Titlebar end */}

                    <div className="p-4 bulk-activation-container">
                        <Row className="justify-content-center">
                            <div className="col-md-6">
                                <div id="prepaid" className="settings-card mt-4 bg-black text-left">
                                    <h5 className="text-center mb-4 title">Activate New Phone Numbers</h5>
                                    <div className="row mt-3 p-4">
                                        <div className="col-sm-12 col-md-6">
                                            <label htmlFor="inputGroupName"># of Phone Numbers to Activate:</label>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <input type="number"
                                                   name="numberInput"
                                                   className="form-control"
                                                   value={this.state.numberInput}
                                                   onChange={this.handleInputChange}
                                                   id="numberToActivate"
                                                   disabled={this.state.loading}/>
                                        </div>
                                        <div className="col-sm-12 col-md-12 text-center">
                                            <button className="btn btn-primary my-3"
                                                    onClick={this.handleActivate}
                                                    disabled={this.state.loading || !this.state.numberInput}>Activate
                                            </button>
                                            {!!this.state.numberActivated &&
                                            <ProgressBar
                                                now={~~(this.state.numberActivated / this.state.numberToActivate * 100)}
                                                variant={this.state.error ? "danger" : "success"}
                                                label={this.state.numberActivated + "/" + this.state.numberToActivate}/>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div id="recurring" className="mt-4 settings-card text-left">
                                    <h5 className="text-center title">Activated Phone Numbers</h5>
                                    <div className="row mt-3 p-4">
                                        <div className="col-12 text-center">
                                            <p>{this.state.activatedNumbers.length} phone numbers activated</p>
                                            <CSVLink data={this.state.activatedNumbers.map(an => ({
                                                "Phone": an.phone_number,
                                                "ActivationTime": formatTime(an.activation_time)
                                            }))}
                                                     filename={"slytextCompanionNumbers.csv"}
                                                     headers={[
                                                         {label: "Phone number", key: "Phone"},
                                                         {label: "Activation Time", key: "ActivationTime"}
                                                     ]}>
                                                <button className="btn btn-primary">Download CSV</button>
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>

                </div>
            </div>
        );
    }
}
