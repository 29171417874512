/* eslint-disable */

import React, {PureComponent} from "react";
import {Link} from "react-router-dom";
import ContactListVirtualized from "./ContactListVirtualized";

export default class ContactList extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            contactFilterString:"",
            contacts:this.props.contacts.items,
        }
    }
    componentWillReceiveProps(props){
        if (props.contacts !== this.props.contacts) {
            this.setState({
                contacts:props.contacts.items
            })
        }
    }



    render() {
        return (
            <div className=" flex-flow-column h-100">
                 <div className="container my-2 bg-muted">
                    <input className="form-control rounded empty"
                            placeholder="&#xF002; Search contacts..."
                            onChange={(e)=>{
                                this.setState({
                                    contactFilterString:e.target.value.toLowerCase(),
                                    contacts:e.target.value ? this.props.contacts.items.filter(contact => contact.name.toLowerCase().includes(this.state.contactFilterString) ||
                                        contact.phone.includes(this.state.contactFilterString)) : this.props.contacts.items
                                })
                            }}/>
                </div>
                <div className="container">
                    <div className="row">
                        <Link to="/contacts/new/edit"
                              className="btn col-12 btn-light conversation-list-item h-auto">
                            <p className="p-2 m-0"><i className="fas fa-plus"></i> Create contact</p>
                        </Link>
                    </div>
                </div>


                {this.state.contacts.length>0 ?
                    <ContactListVirtualized contacts={this.state.contacts} ui={this.props.ui}/>
                    :
                    <div className={"text-muted col-10 offset-1"}>
                        <br/>
                        No contacts available.
                    </div>
                }
            </div>
        )
    }
}
