/* eslint-disable */

import React, {Component} from "react";
import CSVReader from "react-csv-reader";
import {toast} from "react-toastify";

import ContactServices from "../../Services/ContactServices";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import {CONTACT_SETTINGS, createToast} from "../../helpers/ToastIdHelper";
import {extractPhoneString} from "../../helpers/FormattingHelper";
import {CSV_PARSE_ERROR_INVALID_FILE, CSV_PARSE_ERROR_NO_DATA, parseCsv} from "../../helpers/CsvDataParser";

class BulkImportContacts extends Component {
    constructor(props) {
        super(props);
        this.state={
            contacts:[],
            contactsString:"",
        };
        this.toastId=CONTACT_SETTINGS;
    }

    handleCsvUpload=(data,file)=>{
        let parsed = parseCsv(data,file);

        if(parsed.err !== null){
            switch (parsed.err) {
                case CSV_PARSE_ERROR_INVALID_FILE:
                    createToast(this.toastId,NotificationHelpers.ERROR_INVALID_CSV,toast.TYPE.ERROR);
                    return;
                case CSV_PARSE_ERROR_NO_DATA:
                    createToast(this.toastId,NotificationHelpers.ERROR_CSV_NO_DATA);
                    return;
            }
        }

        let contacts = parsed.contacts;
        this.showImportedContacts(contacts);
    };

    showImportedContacts(contacts) {
        const invalid = contacts.filter(con => con.invalid);
        contacts = contacts.filter(con => con.invalid !== true);
        createToast(this.toastId,"Imported " + contacts.length + " contacts. " +
                          invalid.length + " invalid entries found.", toast.TYPE.INFO);
        this.setState({
            contacts: contacts,
            invalidContacts: invalid,
            invalidString: invalid.map(con => con.name + ", " + con.phone).join("\n"),
            contactsString: contacts.map(con => con.name + ", " + con.phone).join("\n")
        })
    };

    handleSave=(e)=>{
        e.preventDefault();
        const contactServices=ContactServices.getInstance();
        contactServices
            .addBulkContacts(this.state.contacts.map(con=>({name:con.name,phone:extractPhoneString(con.phone)})))
            .then(res=>{
                createToast(this.toastId, res.importCount +" saved, " +res.errorContacts.length+" skipped.",toast.TYPE.SUCCESS);
                this.props.fetchContacts();
            })
            .catch(() => {
                createToast(this.toastId, NotificationHelpers.ERROR_INSERT_BULK_CONTACT, toast.TYPE.ERROR);
            })
    };

    handleMembersEdit=(e)=>{
        let membersStrings = e.target.value.split('\n');
        this.setState({
            contactsString:e.target.value,
            contacts:membersStrings.map(str => {
                    if(str.includes(",")){
                        const name = str.split(',')[0];
                        const phone = extractPhoneString(str.split(',')[1]);
                        return {name:name,phone:phone}

                    }else{
                        const name=extractPhoneString(str);
                        const phone=extractPhoneString(str);
                        return {name:name,phone:phone}
                    }
                })
        })
    };

    render() {
        return (
            <div className={"message-container"}>
                <div className={"conversation-container"}>

                    {/* Title start */}
                    <div className={"conversation-title-container text-left p-3 align-middle"}>
                        <span className="text-light p-2 title">
                            Create multiple contacts
                        </span>
                    </div>
                    {/* Title end */}

                    <div className={"container text-left p-4"}>
                        <form className="col-sm-12 p-0 mt-4">
                            <div className={"form-group inline row"}>
                                <div className={"col-md-2 col-sm-12"}>
                                    <label htmlFor="message" className="text-dark">Enter contacts:</label>
                                </div>
                                <div className={"col-md-7 col-sm-12"}>
                                    <textarea   className="col-12 form-control"
                                            placeholder="Ex: 6173999980 OR John Smith, 673999980"
                                            rows={4}
                                            name="contactsString"
                                            value={this.state.contactsString}
                                            onChange={this.handleMembersEdit}/>
                                    <small className={"font-italic"}>{this.state.contacts.length} contacts</small>
                                    <p className={""}>Format:<br/>
                                           Contact name, Phone number (Digits only)<br/>
                                           Max 1,000 contacts </p>
                                </div>
                            </div>
                            <div className={"form-group inline row"}>
                                <div className={"col-md-2 col-sm-12"}>
                                    <label htmlFor="message" className="text-dark">Import from CSV:</label>
                                </div>
                                <div className={"col-md-7 col-sm-12"}>
                                    <CSVReader
                                        cssClass="csv-reader-input"
                                        label=""
                                        onFileLoaded={this.handleCsvUpload}
                                        // onError={this.handleDarkSideForce}
                                        inputId="ObiWan"
                                        inputStyle={{color: 'red',
                                                     border: "1px solid #ccc",
                                                     borderRadius:"0.25rem",
                                                     display: "inline-block",
                                                     padding: "6px 12px",
                                                     backgroundColor:"#ccc",
                                                     cursor: "pointer"}}
                                    />
                                    <p className={" mt-2"}>Comma Delimited (.CSV) files only.<br/>
                                        Contact Name in the first column.<br/>
                                        Phone Number in the second column. (Digits only).<br/>
                                    </p>
                                    {this.state.invalidString ?
                                        <div>
                                            <label className={"text-danger"}>Invalid entries in CSV</label>
                                            <textarea className="col-12 form-control"
                                                      placeholder="Ex: 6173999980 OR John Smith, 673999980"
                                                      rows={4}
                                                      name="invalidString"
                                                      value={this.state.invalidString}
                                                      readOnly={true}/>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Footer start */}
                    <div className={"settings-button-footer"}>
                        <div className={"row p-2"}>
                            <div className="col-sm-12 col-md-2 p-1 offset-md-10">
                                <button className="col-12 btn btn-primary"
                                        onClick={this.handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Footer start */}


                </div>
            </div>
        );
    }
}

BulkImportContacts.propTypes = {};

export default BulkImportContacts;