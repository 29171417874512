/* eslint-disable */
import React, {Component} from "react";
import "../../assets/style/chatmessages.css";
import {setTime} from "../../helper";
import MediaViewer from "./MediaViewer";
import Linkify from "react-linkify";
import {ButtonToolbar} from "react-bootstrap";
import DeliveryStatusModal from "./DeliveryStatusModalComponent";
import {Link} from "react-router-dom";


class OutgoingMessageItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
        this.toggleModal = this.toggleModal.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkMessageParam();
    }

    componentDidMount() {
        this.checkMessageParam();
    }

    checkMessageParam() {
        const currentHref = window.location.href;
        const hrefComponents = currentHref.split("?message=");
        if (!this.state.modalShow && hrefComponents.length === 2 && hrefComponents[1] === this.props.message.id + "") {
            this.setState({
                modalShow: true
            });
        }
    }
    
    toggleModal() {
        if (this.state.modalShow) {
            const currentHref = window.location.href;
            const hrefComponents = currentHref.split("?message=");
            if (hrefComponents.length > 1) {
                window.location.href = hrefComponents[0];
            }
            this.setState({
                modalShow: false
            });
        } else {
            this.setState({
                modalShow: true
            });
        }
    }

    render() {
        return (
            <div className="right text-right" style={{minWidth: "20%", maxWidth: "70%", marginBottom: "10px"}}>
                <div className="outgoing-message ">
                <span id="title"> You {this.props.broadcastGroup ?
                    <Link to={"/groups/" + this.props.broadcastGroup.id}
                          title={"Go to group"}
                          className={"font-weight-light"}
                          style={{color: "#3a80da"}}>
                        ({this.props.broadcastGroup.name} <i className="fa fa-bullhorn"/>)
                    </Link>
                    : ""} </span>
                    <br/>
                    <Linkify>
                        <span id="message">{this.props.message.message.split("\n").map((m, i) => {
                            return m.length > 0 ? <p key={i}>{m}</p> : "";
                        })}</span>
                    </Linkify>
                    {this.props.message.fileHash && this.props.message.fileHash != "" ?
                        <span id="file">
                        <MediaViewer {...this.props.message}/>
                    </span> :
                        ""}
                    <br/>
                    <div className="mt-2">
                        <span id="delivered"
                              className={this.props.message.delivered === "true" ? "" : "hidden"}>delivered</span>
                        <span id="timestamp">{setTime(this.props.message.timestamp)}</span>
                    </div>
                </div>
                {this.props.message.group_id > 0 ?
                    <ButtonToolbar className="text-right right">
                        <small>
                            <button className="text-secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleModal();
                                    }}><i className="far fa-file-alt"/> Report
                            </button>
                        </small>

                        <DeliveryStatusModal
                            show={this.state.modalShow}
                            onHide={this.toggleModal}
                            message={this.props.message}
                            group_count={this.props.groupCount}
                        />
                    </ButtonToolbar>
                    :
                    ""
                }
            </div>
        );
    }
}

export default OutgoingMessageItem;