/* eslint-disable */

import React from "react";
import {formatPhoneNumber} from "../../helper";
import {store} from "../../store";
import {setText} from "../../actions/UIActions";
import {Link} from "react-router-dom";

export default function ContactListItem(props) {
    const addingToGroup = store.getState().ui.addingToGroup;
    return (
        <div className="container conversation-list-item p-0 " onClick={event => {
            if (addingToGroup) {
                event.preventDefault();
            }
        }}>
            <div className="row p-1">
                <div className="col-3 ">
                    <img className=" round" 
                        src={"https://slytext.com/"+props.contact.avatar}
                        alt=""/>
                </div>
                <div className="col-7 bottom-bordered row p-0">
                    <div className="col-12 text-left">
                        <div className="row">
                            <div className="col-8 title">
                                {props.contact.name}
                                <br/>
                                <span className="text-muted small">
                                    {formatPhoneNumber(props.contact.phone)}
                                </span>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-2 align-self-center">
                    {addingToGroup && <i className="fas fa-plus-circle" style={{fontSize: "20px"}} onClick={() => {
                        const text = props.contact.name === props.contact.phone ? props.contact.phone : (props.contact.name + "," + props.contact.phone);
                        store.dispatch(setText(text));
                    }} title="Add to group members"/>}
                    {!addingToGroup && <Link to={"/contacts/"+props.contact.id+"/edit"}
                                             className="btn round"
                                             title="Contact Settings">
                        <i className="fas fa-eye text-muted"/>
                    </Link>}
                </div>
            </div>
    </div>
    )
}
