/* eslint-disable */
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {CardElement, injectStripe, PaymentRequestButtonElement} from "react-stripe-elements";
import {toast} from "react-toastify";
import {emailIsValid} from "../../helpers/validation-helper";
import SignupServices from "../../Services/SignupServices";
import SubscriptionServices from "../../Services/SubscriptionServices";
import NavbarFull from "./NavbarFull";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import "../../assets/style/signup-form.css";
import "../../assets/style/webflow.css";
import "../../assets/style/normalize.css";
import SignUpSlider from "./SignupSlider";
import {createToast} from "../../helpers/ToastIdHelper";
import TagManager from "react-gtm-module";

const subscriptionServices = SubscriptionServices.getInstance();
const signupServices = SignupServices.getInstance();
const braintree = require('braintree-web');
// const BRAINTREE_TOKEN = "sandbox_v2cvcx62_7zvbdy4fqn9gxw37";
const BRAINTREE_TOKEN = "production_gptf22cv_sz2tqm5ks6r4gzxs";
const tagManagerArgs = {
    gtmId: "G-HV48G305M9",
    dataLayer: {
        page: "signup"
    },
};

const tagManagerArgsOnCreateAccount = {
    gtmId: "G-HV48G305M9",
    event: "createAccount",
    events: {
        createAccount: "createAccount"
    },
    dataLayer: {
        event: "createAccount",
        page: "create-account"
    },
};

const routeToApp = '/app';

class SignupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirm: "",
            plans: [],
            sendingRequest:false,
            promocode:'',
            braintreeNonce: "",
            braintreeEmail: "",
            braintreeDevice: "",
            paymentRequest: null,
            applepayToken: null,
            applepayer: null
        };
        this.form = React.createRef();
        this.toastId = 106;
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.createApplePayOptions = this.createApplePayOptions.bind(this)
    }

    componentDidMount() {
        TagManager.initialize(tagManagerArgs);
        subscriptionServices.getPlans()
            .then(res => {
                    res = res.sort((a, b) => (a.price - b.price));
                    let plans = res.filter(plan => !plan.recurring);
                    plans = plans.concat(res.filter(plan => plan.recurring));
                    this.setState({
                        plans: plans
                    })
                }
            )
            .catch(err => {
                createToast(this.toastId,NotificationHelpers.SIGNUP_500,toast.TYPE.ERROR)
            });
        const that = this;
        braintree.setup(BRAINTREE_TOKEN, "custom", {
            paypal: {
                container: "paypal-container",
                singleUse: false,
                onError: function() {
                    document.getElementById("card-container").style.display = "block";
                    document.getElementById("applepay-container").style.display = "block";
                },
                onCancelled: function() {
                    document.getElementById("card-container").style.display = "block";
                    document.getElementById("applepay-container").style.display = "block";
                },
                onAuthorizationDismissed: function() {
                    document.getElementById("card-container").style.display = "block";
                }
            },
            dataCollector: {
                paypal: true
            },
            onPaymentMethodReceived: function (obj) {
                that.setState({
                    braintreeNonce: obj.nonce,
                    braintreeEmail: obj.details.email
                });
                document.getElementById("card-container").style.display = "none";
                document.getElementById("applepay-container").style.display = "none";
            },
            onReady: function (braintreeInstance) {
                that.setState({
                    braintreeNonce: "",
                    braintreeEmail: "",
                    braintreeDevice: braintreeInstance.deviceData
                });
            }
        });
    }

    createApplePayOptions(amount){
        if(!this.props.stripe){
            return
        }
        const pr = this.props.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Total',
                amount: amount,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
            // only allow applePay, result contain three payment method flag applePay/googlePay/link
            if (result && result.applePay) {
                this.setState({
                    paymentRequest: pr
                })
            }
        });

        pr.on("token", (evt)=>{
            this.setState({applepayToken: evt.token.id, applepayer: {email: evt.payerEmail, name: evt.payerName}});
            document.getElementById("card-container").style.display = "none";
            document.getElementById("paypal-container").style.display = "none";
            evt.complete("success");
        })
    }

    async handleFormSubmit(ev) {
        if(this.state.sendingRequest){
            return;
        }
        this.setState({sendingRequest:true});
        if (!this.form.current.reportValidity()) {
            this.setState({sendingRequest: false});
            return;
        }

        if (this.state.emailCheckStatus === false) {
            createToast(this.toastId,this.state.emailCheckError,toast.TYPE.ERROR);
            this.setState({sendingRequest:false});
            return;
        }
        if (this.state.password != this.state.passwordConfirm) {
            createToast(this.toastId,NotificationHelpers.PASSWORD_MISMATCH_ERROR,toast.TYPE.ERROR);
            this.setState({sendingRequest:false});
            return
        }
        const paymentData = {
            stripeToken: this.state.applepayToken,
            braintreeEmail: this.state.braintreeEmail,
            braintreeNonce: this.state.braintreeNonce,
            braintreeDevice: this.state.braintreeDevice
        };
        if (!this.state.braintreeNonce && !this.state.applepayToken) {
            let {token} = await this.props.stripe.createToken({name: this.state.name});
            if (token == undefined) {
                createToast(this.toastId,NotificationHelpers.INVALID_CARD,toast.TYPE.ERROR);
                this.setState({sendingRequest:false});
                return;
            }
            paymentData.stripeToken = token.id;
        }
        signupServices.signUp(this.state)
            .then(res => {
                TagManager.initialize(tagManagerArgsOnCreateAccount);
                const selectedPlan = this.state.plans.find(plan => plan.id === this.state.plan);
                subscriptionServices.updateCreditCard(paymentData)
                    .then(res=>{
                        if (selectedPlan.recurring) {
                            subscriptionServices.subscribeToPlan(selectedPlan.id, paymentData.stripeToken)
                                .then(res => {
                                    if (res && res.status == 500) {
                                        throw(res);
                                    }
                                    createToast(this.toastId,NotificationHelpers.SUCCESSFUL_SIGN_UP,toast.TYPE.SUCCESS);
                                    toast.success("Successfully subscribed to a plan.");
                                    if(this.state.promocode!==''){
                                        subscriptionServices.applyPromoCode(this.state.promocode)
                                            .then(res => {
                                                toast.success("Successfully applied promotion. Added " +
                                                    res.message_credit+" credits to your account.");
                                                this.setState({sendingRequest:false});
                                                this.props.history.push(routeToApp)
                                            })
                                            .catch(err => {
                                                err.then(text=>{
                                                    toast.error(JSON.parse(text).error);
                                                    this.setState({sendingRequest:false});
                                                    this.props.history.push(routeToApp)
                                                })

                                            })
                                    }else{
                                        this.setState({sendingRequest:false});
                                        this.props.history.push(routeToApp)
                                    }
                                    subscriptionServices.sendWelcomeEmail().catch(err => console.error(err));
                                })
                                .catch(err => {
                                    subscriptionServices.removeInvalidUser().then(res => {
                                        err.text()
                                        .then(err => {
                                            err = JSON.parse(err);
                                            toast.error(NotificationHelpers.UNABLE_TO_SUBSCRIBE);
                                        });
                                        this.setState({sendingRequest:false})
                                    });

                                })
                        } else {
                            subscriptionServices.creditMessages(selectedPlan.messageCount, paymentData.stripeToken)
                                .then(res => {
                                    if (res && res.status == 500) {
                                        throw(res);
                                    }
                                    createToast(this.toastId,NotificationHelpers.SUCCESSFUL_SIGN_UP,toast.TYPE.SUCCESS);
                                    toast.success("Successfully purchased message credit.");
                                    if(this.state.promocode!==''){
                                        subscriptionServices.applyPromoCode(this.state.promocode)
                                            .then(res => {
                                                toast.success("Successfully applied promotion. Added " +
                                                    res.message_credit+" credits to your account.");
                                                this.setState({sendingRequest:false});
                                                this.props.history.push(routeToApp)
                                            })
                                            .catch(err => {
                                                err.then(text=>{
                                                    toast.error(JSON.parse(text).error);
                                                    this.setState({sendingRequest:false});
                                                    this.props.history.push(routeToApp)
                                                });
                                                this.setState({sendingRequest:false});
                                                this.props.history.push(routeToApp)
                                            })
                                    }else{
                                        this.setState({sendingRequest:false});
                                        this.props.history.push(routeToApp)
                                    }
                                    subscriptionServices.sendWelcomeEmail().catch(err => console.error(err));
                                })
                                .catch(err => {
                                    subscriptionServices.removeInvalidUser().then(res => {
                                        err.text()
                                        .then(err => {
                                            err = JSON.parse(err);
                                            toast.error(NotificationHelpers.UNABLE_TO_SUBSCRIBE);
                                        });
                                        this.setState({sendingRequest:false})
                                    });
                                })
                        }
                    })
                    .catch(err=>{
                        subscriptionServices.removeInvalidUser().then(res => {
                            err.text()
                            .then(err => {
                                err = JSON.parse(err);
                                toast.error("Could not process your payment information. Please try a different card.")
                            });
                            this.setState({sendingRequest:false})
                        });
                    })

            })
            .catch(err => {
                err.text()
                    .then(err => {
                        err = JSON.parse(err);
                        createToast(this.toastId,"Error: " + err.error,toast.TYPE.ERROR);
                        this.setState({sendingRequest:false})
                    })
            })
    }

    handleInputChange = (e) => {
        // create PaymentRequest Object
        if(e.target.name === 'plan'){
            const plan = this.state.plans.filter(plan => plan.id === e.target.value)[0]
            if(plan && plan.price){
                const price = plan ? plan.price : 0
                this.createApplePayOptions(price)
            }
        }
        if (e.target.name === "confirm") {
            if (this.state.openTerms) {
                e.target.setCustomValidity("");
            } else {
                e.target.setCustomValidity("Please review the Terms of Service before proceeding.");
                e.target.checked = false;
                this.form.current.reportValidity();
                return;
            }
        }
        if (e.target.name === "10dlc") {
            if (this.state.open10Dlc) {
                e.target.setCustomValidity("");
            } else {
                e.target.setCustomValidity("Please review the 10-DLC requirements before proceeding.");
                e.target.checked = false;
                this.form.current.reportValidity();
                return;
            }
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleEmailCheck = (e) => {
        if (emailIsValid(e.target.value)) {
            signupServices.checkEmail(e.target.value)
                .then(res => {
                    if (res.status) {
                        this.setState({
                            emailCheckStatus: true,
                            emailCheckError: null
                        })
                    } else {
                        this.setState({
                            emailCheckStatus: false,
                            emailCheckError: res.error
                        })
                    }
                })
        } else {
            this.setState({
                emailCheckStatus: false,
                emailCheckError: NotificationHelpers.INVALID_EMAIL
            })
        }

    };

    render() {
        const {paymentRequest} = this.state;
        return (
            <div>
                <NavbarFull signedOut="true"/>

                <div className="container text-left container">
                    <div className={"row mt-4 pt-4"}>
                        <div className="col-md-5 offset-md-2 col-sm-10 offset-sm-1">
                            <div className="form">
                                <div className="text-block">Create Account</div>
                                <p className={"text-muted"}> <span className={"text-danger"}>*</span> Mandatory fields </p>
                                <div className="form-block w-form">
                                    <form id="wf-form-Log-In" name="wf-form-Log-In" data-name="Log In"
                                          className="form-2"
                                          ref={this.form} onSubmit={e => e.preventDefault()}>
                                        <label htmlFor="name" className="field-label">Name<span
                                            className={"text-danger"}>*</span> </label>
                                        <input type="text"
                                               className="text-field w-input"
                                               autoFocus="true"
                                               maxLength="256"
                                               name="name"
                                               data-name="Name"
                                               placeholder="First &amp; last name"
                                               id="name"
                                               onChange={this.handleInputChange}
                                               required/>
                                        <label htmlFor="Email" className="field-label">Email address<span
                                            className={"text-danger"}>*</span></label>
                                        <input type="email"
                                               className="text-field w-input"
                                               maxLength="256"
                                               name="email"
                                               data-name="Email"
                                               placeholder="Enter email"
                                               id="Email"
                                               onChange={this.handleInputChange}
                                               onBlur={this.handleEmailCheck}
                                               required/>
                                        {!this.state.emailCheckStatus ?
                                            <small className="text-danger">{this.state.emailCheckError}</small> :
                                            ""
                                        }
                                        <label htmlFor="Phone-Number-3" className="field-label-2">Phone number<span
                                            className={"text-danger"}>*</span></label>
                                        <input type="tel"
                                               className="text-field-2 w-input"
                                               maxLength="256"
                                               name="phone"
                                               data-name="Phone Number"
                                               placeholder="Phone number"
                                               id="Phone-Number-3"
                                               onChange={this.handleInputChange}
                                               required/>

                                        <div className="row ">
                                            <div className={"col-6"}>
                                                <label htmlFor="Phone-number-3" className="field-label-2">Password<span
                                                    className={"text-danger"}>*</span></label>
                                                <input type="password"
                                                       className="text-field-2 w-input"
                                                       maxLength="256"
                                                       name="password"
                                                       data-name="Password"
                                                       placeholder="At least 8 characters"
                                                       id="Password"
                                                       onChange={this.handleInputChange}
                                                       required/>
                                            </div>
                                            <div className={"col-6"}>
                                                <label htmlFor="Phone-number-3" className="field-label-2">Confirm
                                                    password<span className={"text-danger"}>*</span></label>
                                                <input type="password"
                                                       className="text-field-2 w-input"
                                                       maxLength="256"
                                                       name="passwordConfirm"
                                                       data-name="Confirm password"
                                                       placeholder="Enter password again"
                                                       id="Confirm-password"
                                                       onChange={this.handleInputChange}
                                                       required/>
                                            </div>
                                        </div>

                                        <label htmlFor="field-2" className="field-label-2">
                                            Select a plan<span className={"text-danger"}>*</span>
                                        </label>
                                        <select id="field-2"
                                                required
                                                onChange={this.handleInputChange}
                                                name="plan"
                                                className="select-field w-select">
                                            <option default>Select a plan</option>
                                            {this.state.plans.map(plan =>
                                                <option key={plan.id} value={plan.id}>{plan.name} |
                                                    ${plan.price / 100}</option>)}
                                        </select>
                                        <p className="text-block-4 text-left mt-1"><span
                                            className={"text-danger"}>*</span> $12 monthly fee for phone number <span
                                            className="font-italic">not</span> included.</p>
                                        <label htmlFor="Phone-number-3" className="field-label-2">Payment details<span
                                            className={"text-danger"}>*</span></label>
                                        <div id="card-container" className=" w-input text-field-2">
                                            <CardElement/>
                                        </div>
                                        <div className={"row"}>
                                            <div className={this.state.braintreeNonce ? "col-12" : "col-4"}>
                                                <div id="paypal-container" className="my-2 w-100"/>
                                            </div>
                                            <div id={"applepay-container"}
                                                 className={this.state.applepayToken ? "col-12" : "col-4"}>
                                                {this.state.applepayToken ?
                                                    (<>
                                                        <hr/>
                                                        <div className="my-2 px-1">
                                                            <span><b>Apple Pay</b>: {this.state.applepayer.email}</span>
                                                            <a onClick={() => {
                                                                this.setState({
                                                                    applepayToken: null,
                                                                    applepayer: null
                                                                });
                                                                document.getElementById("card-container").style.display = "block";
                                                                document.getElementById("paypal-container").style.display = "block";
                                                            }} className="float-right small" style={{
                                                                textDecoration: "underline",
                                                                cursor: "pointer"
                                                            }}>Cancel</a>
                                                        </div>
                                                        <hr/>
                                                    </>)
                                                    :
                                                    (paymentRequest &&
                                                        <PaymentRequestButtonElement paymentRequest={paymentRequest}
                                                                                     className="w-100 my-2" style={{
                                                            paymentRequestButton: {
                                                                type: "default",
                                                                theme: "dark",
                                                                height: "44px",
                                                            },
                                                        }}/>)}
                                            </div>
                                        </div>
                                        <label htmlFor="promocode" className="field-label-2">
                                            Referral code
                                        </label>
                                        <input type="text"
                                               className="text-field-2 w-input"
                                               maxLength="15"
                                               name="promocode"
                                               placeholder="Enter code"
                                               id="promocode"
                                               onChange={this.handleInputChange}
                                        />
                                        <div className="mt-3">
                                            <input type="checkbox" style={{marginRight: "10px"}} name="confirm"
                                                   onChange={this.handleInputChange} required/>
                                            <span>I agree to the slyText <a target="_blank"
                                                                            href="https://slytext.com/terms/"
                                                                            onClick={() => this.setState({openTerms: true})}>Terms of Service</a></span>
                                        </div>
                                        <div className="mb-4">
                                            <input type="checkbox" style={{marginRight: "10px"}} name="10dlc"
                                                   onChange={this.handleInputChange} required/>
                                            <span>I have read and understand the <a target="_blank"
                                                                                    href="https://slytext.com/10-dlc-best-practices.html"
                                                                                    onClick={() => this.setState({open10Dlc: true})}>10-DLC requirements</a></span>
                                        </div>
                                        <button
                                            type="submit"
                                            className={this.state.sendingRequest ? "button w-button bg-secondary" : "button w-button"}
                                            onClick={this.handleFormSubmit}
                                            id={"submit-signup"}
                                            disabled={this.state.sendingRequest}>{this.state.sendingRequest ? "Please wait..." : "Submit"}
                                        </button>
                                    </form>

                                    <div className="w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"col-md-4 col-sm-0 d-none d-md-block"}>
                            <SignUpSlider/>
                        </div>
                    </div>
                </div>
            </div>
    )
    }
    }

    export default withRouter(injectStripe(SignupComponent))
