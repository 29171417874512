/* eslint-disable */

import React, {Component} from "react";
import ReactLoading from "react-loading";
import PaymentIcon from "react-payment-icons";
import {toast} from "react-toastify";
import "../../assets/style/account-settings.css";
import SubscriptionServices from "../../Services/SubscriptionServices";
import NavbarFull from "./NavbarFull";
import {CardElement, injectStripe} from "react-stripe-elements";
import {formatPhoneNumber, formatTime, formatUsd} from "../../helpers/FormattingHelper";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import {Link} from "react-router-dom";
import AppsumoServices from "../../Services/AppsumoServices";
import AuthServices from "../../Services/AuthServices";
import {Modal} from "react-bootstrap";

class SubscriptionComponent extends Component {
    constructor(props){
        super(props);
        this.state= {
            customer: this.props.user.customer.item,
            plans: this.props.plans.items ? this.props.plans.items.sort((a, b) => a.price - b.price) : [],
            payment: this.props?.user?.profile?.braintree_customer ? {info: "Paypal on file"} : (this.props.user.customer.item.default_source ?
                this.props.user.customer.item.sources.data.find(card => card.id === this.props.user.customer.item.default_source)
                : {error: "No card available"}),
            selectPrepaidMessageCount: -1,
            selectedPlanId: "",
            changeCard: false,
            appsumoPlan: null,
            noticeModal: false,
            receiptModal: false,
            charges: []
        };
        this.handleCardChange = this.handleCardChange.bind(this)
        this.closeNoticeModal = this.closeNoticeModal.bind(this)
        this.handleCancelAccount = this.handleCancelAccount.bind(this);
        this.toggleReceiptModal = this.toggleReceiptModal.bind(this);
    }

    componentWillReceiveProps(props){
        const customer = props.user.customer.item;
        this.setState({
            customer:customer,
            plans:props.plans.items?props.plans.items.sort((a,b)=>a.price-b.price):[],
            payment: this.props?.user?.profile?.braintree_customer ? {info:"Paypal on file"} : (customer && customer.default_source?
                        props.user.customer.item.sources.data.find(card=>card.id===props.user.customer.item.default_source)
                        :{error:"No card available"})
        });
        if (!this.state.appsumoPlan && this.props.user && this.props.user.profile && this.props.user.profile.appsumo_plan) {
            const appsumoServices = AppsumoServices.getInstance();
            appsumoServices.getAppsumoPlan(this.props.user.profile.appsumo_plan)
                .then(res => {
                        this.setState({
                            appsumoPlan: res
                        });
                    }
                );
        }
    }

    componentDidMount(){
        this.props.fetchPlans();
        this.props.fetchCustomer();
        this.props.fetchProfile();
    }

    handleInputChange=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    };

    handleCheckboxChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.checked
        });
        e.target.setAttribute('checked', e.target.checked);
    };

    handleSubscribe=(e)=>{
        e.preventDefault();
        if(this.state.payment.error){
            toast.error(NotificationHelpers.ERROR_NO_PAYMENT_CARD_FOUND);
            return
        }
        if(this.state.selectedPlanId==="") {
            toast.warn("Select a plan");
            return
        }
        const toastId = toast("Subscribing, please wait...", { autoClose: false });
        const subscriptionService = SubscriptionServices.getInstance();
        subscriptionService.subscribeToPlan(this.state.selectedPlanId)
            .then(res => {
                this.props.fetchCustomer();
                this.props.fetchProfile();

                toast.update(toastId, {
                    render: "Subscribed to "+this.state.selectedPlanId,
                    type: toast.TYPE.SUCCESS,
                    className: 'rotateY animated',
                    autoClose: true
                })
            })
            .catch(err=>{
                err.text().then(err => {
                    const error = JSON.parse(err).error;
                    toast.update(toastId, {
                        render: error,
                        type: toast.TYPE.ERROR,
                        className: 'rotateY animated',
                        autoClose: true
                    })
                })

            })

    };

    handlePrepaidMessagePurchase=(e)=>{
        e.preventDefault();
        if(this.state.payment.error){
            toast.error(NotificationHelpers.ERROR_NO_PAYMENT_CARD_FOUND);
            return
        }
        if(this.state.selectPrepaidMessageCount===-1) {
            toast.warn(NotificationHelpers.WARN_SELECT_PREPAID_PLAN);
            return
        }
        const toastId = toast("Adding, please wait...", { autoClose: false });
        const subscriptionService = SubscriptionServices.getInstance();
        subscriptionService.creditMessages(parseInt(this.state.selectPrepaidMessageCount),null)
            .then(res => {
                this.props.fetchCustomer();
                this.props.fetchProfile();

                toast.update(toastId, {
                    render: "Credited "+this.state.selectPrepaidMessageCount+" messages to your account.",
                    type: toast.TYPE.SUCCESS,
                    className: 'rotateY animated',
                    autoClose: true
                })
            })
            .catch(err=>{
                err.text().then(err => {
                    const error = JSON.parse(err).error;
                    toast.update(toastId, {
                        render: "Could not credit messages: "+error,
                        type: toast.TYPE.ERROR,
                        className: 'rotateY animated',
                        autoClose: true
                    })
                })

            })

    };

    handleCancelSubscription=(e)=>{
        e.preventDefault();
        const ss = SubscriptionServices.getInstance();
        ss.cancelSubscription()
            .then(res => {
                toast.success(NotificationHelpers.SUCCESS_SUBSCRIPTION_CANCEL);
                this.props.fetchCustomer();
                this.props.fetchProfile();

            })
            .catch(err => {
                err.text().then(err => {
                    const error = JSON.parse(err).error;
                    toast.error(error)
                })
            })
    };

    async handleCardChange(e){
        e.preventDefault();
        let {token} = await this.props.stripe.createToken({name: this.state.name});
        if (token === undefined) {
            toast.error(NotificationHelpers.INVALID_CARD);
            return;
        }
        const ss = SubscriptionServices.getInstance();
        ss.updateCreditCard({
            stripeToken: token.id,
            braintreeEmail: this.state.braintreeEmail,
            braintreeNonce: this.state.braintreeNonce,
            braintreeDevice: this.state.braintreeDevice
        }).then(res => {
            toast.success(NotificationHelpers.SUCCESS_CARD_UPDATE);
            this.props.fetchCustomer();
            this.props.fetchProfile();
        }).catch(err => {
            toast.error(NotificationHelpers.ERROR_CARD_UPDATE);
        });
        this.setState({
            changeCard: false
        });
    }

    closeNoticeModal() {
        this.setState({noticeModal: false});
    }

    toggleReceiptModal() {
        this.setState({receiptModal: !this.state.receiptModal});
        if (!this.state.receiptModal) {
            const subscriptionService = SubscriptionServices.getInstance();
            subscriptionService.getCharges().then(charges => {
                this.setState({charges: charges || []});
            });
        }
    }

    handleCancelAccount() {
        const auth = AuthServices.getInstance();
        auth.cancelAccount().then(() => {
            auth.signOut();
            window.location.href = "/app";
        });
    }

    render() {
        let customerSubscriptions = [];
        if (this.state.plans && this.state.customer && this.state.customer.subscriptions && this.state.customer.subscriptions.data) {
            customerSubscriptions = this.state.customer.subscriptions.data.filter(s => this.state.plans.some(p => p.id === s.plan.id));
        }
        if (this.state.plans && this.props.user?.profile?.braintree_plan) {
            const plan = this.state.plans.find(p => p.id === this.props.user.profile.braintree_plan);
            if (plan) {
                customerSubscriptions = [{
                    plan,
                    status: new Date(this.props.user.profile.braintree_paid_until) < new Date() ? "past_due" : "active",
                    cancel_at_period_end: !!this.props.user?.profile?.braintree_cancelled,
                    current_period_end: new Date(this.props.user.profile.braintree_paid_until).getTime() / 1000
                }];
            }
        }
        return (
            <div className="mb-4">
                <NavbarFull/>
                <h3 className="mt-4">Subscription Settings</h3>
                <div className="container col-8">
                {this.props.plans.loading||this.props.user.customer.loading?
                    <ReactLoading type="spin"
                    color={'#000000'}
                    className={'centered'}
                    />
                    :
                    <div>
                        <div id="details" className=" mt-4  col-md-12 p-0 pb-4 text-center settings-card">
                            <h5 className="text-center mb-4 title">Details</h5>
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <label htmlFor="slyTextPhone">SlyText Phone Number:</label>
                                    {this.props.user.profile.slytext_phone?formatPhoneNumber(this.props.user.profile.slytext_phone):
                                        <div>
                                            {this.state.payment.error
                                            || (this.props.user.profile.message_credit===0 && this.props.user.profile.message_credit===0)
                                                ?"Please purchase a plan to choose your slyText phone number."
                                                :<Link className={"btn btn-secondary btn-sm"} to={"/welcome"}>Select a phone number</Link>}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-12">
                                    <label htmlFor="slyTextPhone">Remaining Prepaid Messages:</label>

                                    {this.props.user.profile.message_credit}
                                </div>
                            </div>
                            {this.props.user.profile.plan_id!=""?
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <label htmlFor="slyTextPhone">Remaining Monthly Messages:</label>
                                        {this.props.user.profile.subscription_quota}
                                    </div>
                                </div>:
                                ""}
                            {this.props.user.profile.slybroadcast_customer!=""?
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <label htmlFor="slyTextPhone">Remaining Slybroadcast Messages:</label>
                                        {this.props.user.profile.slybroadcast_credits}
                                    </div>
                                </div>:
                                ""}
                            <div className={"row mt-2"}>
                                <div className={"mt-4 col-md-12 p-0 pb-4 text-center"}>
                                    <button className="btn btn-outline-danger"
                                            onClick={() => this.setState({noticeModal: true})}>Cancel Account
                                    </button>
                                    <button className="btn btn-outline-primary ml-3"
                                            onClick={this.toggleReceiptModal}>Receipts
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Card settings */}
                            <div id="details" className=" settings-card mt-4  pb-4 text-left">
                                <h5 className="title text-center mb-4">Payment Details</h5>
                                <div className="row px-4">
                                    <div className="col-sm-12 col-md-1">
                                        <label htmlFor="slyTextPhone">Card:</label>
                                    </div>
                                    <div className="col-sm-12 col-md-10">
                                        {!this.state.changeCard?
                                            <span>
                                            {this.state.payment.error || this.state.payment.info ||
                                                <span>
                                                    <PaymentIcon
                                                        id={this.state.payment.brand.toLowerCase()}
                                                        style={{ margin: 10, width: 50 }}
                                                        className="payment-icon"
                                                    />

                                                    <strong>•••• •••• •••• {this.state.payment.last4} </strong>
                                                    <span className="mx-2">Exp: {this.state.payment.exp_month}/{this.state.payment.exp_year}</span>
                                                </span>
                                            }
                                                <button className="mx-2 btn btn-secondary"
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            this.setState({
                                                                changeCard:true
                                                            })
                                                        }}>Update</button>
                                        </span>

                                            :
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CardElement/>
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-primary col-12"
                                                            onClick={this.handleCardChange}>Save</button>
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-danger col-12"
                                                            onClick={(e)=>{
                                                                e.preventDefault();
                                                                this.setState({
                                                                    changeCard:false
                                                                })
                                                            }}>Cancel</button>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            {/* Subscription settings */}
                            {!this.state.appsumoPlan && <div className="row">
                                <div className="col-md-6">
                                    <div id="prepaid" className="settings-card mt-4 bg-black text-left">
                                        <h5 className="text-center mb-4 title">Prepaid Plan</h5>
                                        <div className="row mt-3 p-4">
                                            <div className="col-sm-12 col-md-3">
                                                <label htmlFor="slyTextPhone">Add Credit:</label>
                                            </div>
                                            <div className="col-sm-12 col-md-9">
                                                <select className="form-control"
                                                        onChange={(e)=>{
                                                            this.setState({
                                                                selectPrepaidMessageCount:e.target.value
                                                            })
                                                        }}>
                                                    <option value={-1}>Select prepaid plan</option>
                                                    {this.state.plans.filter(plan=>!plan.recurring).map(plan=>
                                                        <option value={plan.messageCount} key={plan.name}>{plan.name} | price: ${plan.price/100}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-12 text-center mt-4">
                                                <button className="btn btn-primary"
                                                        onClick={this.handlePrepaidMessagePurchase}> Purchase </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className="col-md-6 col-sm-12">
                                    <div id="recurring" className="mt-4 settings-card text-left">
                                        <h5 className="text-center title">Monthly Subscription</h5>
                                        <div className="row mt-3 p-4">
                                            <div className="col-sm-12 col-md-4">
                                                <label htmlFor="slyTextPhone">Current Subscription:</label>
                                            </div>
                                            <div className="col-sm-12 col-md-8">
                                                {customerSubscriptions.length>0?
                                                    <span>
                                                {this.state.plans.find(plan=>plan.id===customerSubscriptions[0].plan.id).name}
                                                        {customerSubscriptions[0].status==="past_due" && <span className="text-danger ml-2">(PAST DUE)</span>}
                                                        {customerSubscriptions[0].cancel_at_period_end?
                                                            <span className="text-danger ml-2">
                                                        <br/><small>Your plan has been cancelled and will end on {new Date(customerSubscriptions[0].current_period_end*1000).toLocaleDateString()}.</small>
                                                    </span>
                                                            :
                                                            <button className="btn btn-danger ml-2"
                                                                    onClick={this.handleCancelSubscription}>Cancel</button>
                                                        }
                                                </span>
                                                    :
                                                    "You are not subscribed to any recurring plan."
                                                }
                                            </div>

                                            {customerSubscriptions.length < 2 && <>
                                            <div className="col-sm-12 col-md-4">
                                                <label htmlFor="slyTextPhone">Subscribe To:</label>
                                            </div>
                                            <div className="col-sm-12 col-md-8">
                                                <select className="form-control"
                                                        onChange={(e)=>{
                                                            this.setState({
                                                                selectedPlanId:e.target.value,
                                                            })
                                                        }}>
                                                    <option value="" defaultValue>Select a plan</option>
                                                    {this.state.plans.filter(plan=>plan.recurring).map(plan=>
                                                        <option value={plan.id} key={plan.name}>{plan.name} | price: ${plan.price/100}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-12 text-center mt-4">
                                                <button className="btn btn-primary"
                                                        onClick={this.handleSubscribe}
                                                > Subscribe </button>
                                            </div>
                                            </>}
                                        </div>
                                    </div>

                                </div>
                            </div>}
                        </div>

                        {this.state.appsumoPlan && <div>
                            <div id="appsumo" className=" mt-4 col-md-12 p-0 pb-4 text-center settings-card">
                                <h5 className="title text-center mb-4">Your Plan</h5>
                                <div className="row">
                                    <div className="col-sm-12 ">
                                        <label>AppSumo ${this.state.appsumoPlan.Price / 100} Lifetime
                                            Plan - {this.state.appsumoPlan.Messages} messages / month</label>
                                        {this.props.user && this.props.user.profile && this.props.user.profile.appsumo_invoice &&
                                        <a className="btn btn-primary my-3" target="_blank"
                                           href={`https://appsumo.com/account/redemption/${this.props.user.profile.appsumo_invoice}#change-plan`}>Change
                                            Plan</a>}
                                        <div className="row text-left m-3">
                                            <div className="col-sm-6">
                                                <b>Deal Terms</b><br/>
                                                ✓&nbsp;&nbsp; Lifetime access to slyText Monthly Messaging Plan<br/>
                                                ✓&nbsp;&nbsp; No codes, no stacking - just choose the plan that's right for you<br/>
                                                ✓&nbsp;&nbsp; You must activate your license within 60 days of purchase<br/>
                                                ✓&nbsp;&nbsp; All future Monthly Messaging Plan updates<br/>
                                                ✓&nbsp;&nbsp; Ability to upgrade/downgrade between 3 license tiers<br/>
                                                ✓&nbsp;&nbsp; Only for new slyText users who do not have existing accounts<br/>
                                                ✓&nbsp;&nbsp; 60-day money-back guarantee, no matter the reason<br/>

                                            </div>
                                            <div className="col-sm-6">
                                                <b>Features Included in All Plans</b><br/>
                                                ✓&nbsp;&nbsp; Single user account<br/>
                                                ✓&nbsp;&nbsp; Unique phone number<br/>
                                                ✓&nbsp;&nbsp; Easy customer opt-out<br/>
                                                ✓&nbsp;&nbsp; Unlimited contact lists<br/>
                                                ✓&nbsp;&nbsp; Smart reply feature<br/>
                                                ✓&nbsp;&nbsp; Messages never expire<br/>
                                                ✓&nbsp;&nbsp; Scheduled messages<br/>
                                                ✓&nbsp;&nbsp; Text enable any number - landline or voip - USA & CA only<br/>
                                                ✓&nbsp;&nbsp; Call forwarding<br/>
                                                ✓&nbsp;&nbsp; Unbeatable open rates<br/>
                                                ✓&nbsp;&nbsp; Track campaign with your dashboard<br/>
                                                ✓&nbsp;&nbsp; Data analytics<br/>
                                                ✓&nbsp;&nbsp; Business text messaging<br/>
                                                ✓&nbsp;&nbsp; Multimedia messaging<br/>
                                                ✓&nbsp;&nbsp; Zapier integrations<br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>}
                </div>
                <Modal centered className="text-center" size={'md'} onHide={this.closeNoticeModal} show={this.state.noticeModal}>
                    <Modal.Header className="bg-danger">
                        <Modal.Title>We hate to see you go!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i className={"fas fa-warning fa-5x text-danger"}/>
                        <h5>Are you sure you want to cancel your account? Once you cancel your account, you will no
                            longer have access to your slyText number, contacts, groups, and messages. This action
                            cannot be undone.</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" onClick={this.handleCancelAccount}>Cancel Account
                        </button>
                        <button className="btn btn-primary" onClick={this.closeNoticeModal}>Keep Account</button>
                    </Modal.Footer>
                </Modal>
                <Modal centered className="text-center" size={"md"} onHide={this.toggleReceiptModal}
                       show={this.state.receiptModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Your Receipts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!this.state.charges.length && <p>No receipt available.</p>}
                        {!!this.state.charges.length && <table className="table table-sm">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Receipt</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.charges.map((c, i) =>
                                <tr key={c.id}>
                                    <td>
                                        {formatTime(c.created * 1000)}
                                    </td>
                                    <td>
                                        {formatUsd(c.amount / 100)}
                                    </td>
                                    <td>
                                        <a href={c.receipt_url} target="_blank"><i className="fa fa-external-link"/></a>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}


export default injectStripe(SubscriptionComponent)
