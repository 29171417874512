/* eslint-disable */
import React, {Component} from "react";
import AuthServices from "../../Services/AuthServices";
import {toast} from "react-toastify";
import NavbarFull from "./NavbarFull";
import ReactLoading from "react-loading";
import PhoneServices from "../../Services/PhoneNumberServices";
import NotificationHelpers from "../../helpers/NotificationHelpers";

const authServices = AuthServices.getInstance();
const numberServices = PhoneServices.getInstance();

export default class CompleteProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            industry: "",
            industries: [],
            zip_code: "",
            street: "",
            city: "",
            state: "",
            country: "",
            first_name: "",
            last_name: "",
            phone: "",
            sample_message_1: "",
            sample_message_2: "",
            website: "",
            company: "",
            company_email: "",
            use_case: "",
            ein: "",
            entity_type: "",
            stock_symbol: "",
            stock_exchange: "",
            message_flow: "",
            campaign_description: "",
            direct_lending: "",
            loading: false,
            step: 0,
            checkMissing: [],
            checkConfirm: []
        };
        const countries = {
            "PR": "Puerto Rico",
            "PS": "Palestine, State of",
            "PT": "Portugal",
            "PW": "Palau",
            "PY": "Paraguay",
            "QA": "Qatar",
            "AD": "Andorra",
            "AE": "United Arab Emirates",
            "AF": "Afghanistan",
            "AG": "Antigua & Barbuda",
            "AI": "Anguilla",
            "AL": "Albania",
            "AM": "Armenia",
            "AO": "Angola",
            "AQ": "Antarctica",
            "AR": "Argentina",
            "AS": "American Samoa",
            "AT": "Austria",
            "RE": "Réunion",
            "AU": "Australia",
            "AW": "Aruba",
            "AX": "Åland Islands",
            "AZ": "Azerbaijan",
            "RO": "Romania",
            "BA": "Bosnia & Herzegovina",
            "BB": "Barbados",
            "RS": "Serbia",
            "BD": "Bangladesh",
            "RU": "Russia",
            "BE": "Belgium",
            "BF": "Burkina Faso",
            "BG": "Bulgaria",
            "RW": "Rwanda",
            "BH": "Bahrain",
            "BI": "Burundi",
            "BJ": "Benin",
            "BL": "St. Barthélemy",
            "BM": "Bermuda",
            "BN": "Brunei Darussalam",
            "BO": "Bolivia",
            "SA": "Saudi Arabia",
            "SB": "Solomon Islands",
            "BQ": "Caribbean Netherlands",
            "SC": "Seychelles",
            "BR": "Brazil",
            "BS": "Bahamas",
            "SD": "Sudan",
            "SE": "Sweden",
            "BT": "Bhutan",
            "SG": "Singapore",
            "BV": "Bouvet Island",
            "SH": "St. Helena",
            "BW": "Botswana",
            "SI": "Slovenia",
            "SJ": "Svalbard & Jan Mayen",
            "BY": "Belarus",
            "BZ": "Belize",
            "SK": "Slovakia",
            "SL": "Sierra Leone",
            "SM": "San Marino",
            "SN": "Senegal",
            "SO": "Somalia",
            "CA": "Canada",
            "SR": "Suriname",
            "CC": "Cocos (Keeling) Islands",
            "SS": "South Sudan",
            "ST": "Sao Tome and Principe",
            "CD": "Congo, the Democratic Republic",
            "CF": "Central African Republic",
            "SV": "El Salvador",
            "CG": "Congo - Brazzaville",
            "SX": "Sint Maarten",
            "CH": "Switzerland",
            "CI": "Cote d'Ivoire",
            "SZ": "Eswatini",
            "CK": "Cook Islands",
            "CL": "Chile",
            "CM": "Cameroon",
            "CN": "China",
            "CO": "Colombia",
            "CR": "Costa Rica",
            "TC": "Turks & Caicos Islands",
            "TD": "Chad",
            "TF": "French Southern Territories",
            "TG": "Togo",
            "CV": "Cape Verde",
            "CW": "Curaçao",
            "TH": "Thailand",
            "CX": "Christmas Island",
            "TJ": "Tajikistan",
            "CY": "Cyprus",
            "TK": "Tokelau",
            "CZ": "Czechia",
            "TL": "Timor-Leste",
            "TM": "Turkmenistan",
            "TN": "Tunisia",
            "TO": "Tonga",
            "TR": "Turkey",
            "TT": "Trinidad & Tobago",
            "DE": "Germany",
            "TV": "Tuvalu",
            "TW": "Taiwan",
            "DJ": "Djibouti",
            "TZ": "Tanzania",
            "DK": "Denmark",
            "DM": "Dominica",
            "DO": "Dominican Republic",
            "UA": "Ukraine",
            "UG": "Uganda",
            "DZ": "Algeria",
            "UM": "U.S. Outlying Islands",
            "EC": "Ecuador",
            "US": "United States of America",
            "EE": "Estonia",
            "EG": "Egypt",
            "EH": "Western Sahara",
            "UY": "Uruguay",
            "UZ": "Uzbekistan",
            "VA": "Vatican City",
            "ER": "Eritrea",
            "VC": "St. Vincent & Grenadines",
            "ES": "Spain",
            "VE": "Venezuela",
            "ET": "Ethiopia",
            "VG": "British Virgin Islands",
            "VI": "U.S. Virgin Islands",
            "VN": "Vietnam",
            "VU": "Vanuatu",
            "FI": "Finland",
            "FJ": "Fiji",
            "FK": "Falkland Islands (Malvinas)",
            "FM": "Micronesia",
            "FO": "Faeroe Islands",
            "FR": "France",
            "WF": "Wallis and Futuna",
            "GA": "Gabon Republic",
            "GB": "United Kingdom",
            "WS": "Samoa",
            "GD": "Grenada",
            "GE": "Georgia",
            "GF": "French Guiana and French West Indies",
            "GG": "Guernsey",
            "GH": "Ghana",
            "GI": "Gibraltar",
            "GL": "Greenland",
            "GM": "Gambia",
            "GN": "Guinea",
            "GP": "Guadeloupe & Martinique",
            "GQ": "Equatorial Guinea",
            "GR": "Greece",
            "GS": "South Georgia & South Sandwich Islands",
            "GT": "Guatemala",
            "XE": "Abkhazia",
            "GU": "Guam",
            "GW": "Guinea Bissau",
            "GY": "Guyana",
            "XK": "Kosovo",
            "HK": "Hong Kong SAR China",
            "HM": "Heard & McDonald Islands",
            "HN": "Honduras",
            "HR": "Croatia",
            "YE": "Yemen",
            "HT": "Haiti",
            "HU": "Hungary",
            "YT": "Mayotte",
            "ID": "Indonesia",
            "IE": "Ireland",
            "IL": "Israel",
            "IM": "Isle of Man",
            "IN": "India",
            "IO": "British Indian Ocean Territory",
            "ZA": "South Africa",
            "IQ": "Iraq",
            "IS": "Iceland",
            "IT": "Italy",
            "ZM": "Zambia",
            "JE": "Jersey",
            "ZW": "Zimbabwe",
            "JM": "Jamaica",
            "JO": "Jordan",
            "JP": "Japan",
            "KE": "Kenya",
            "KG": "Kyrgyzstan",
            "KH": "Cambodia",
            "KI": "Kiribati",
            "KM": "Comoros",
            "KN": "St. Kitts & Nevis",
            "KR": "South Korea",
            "KW": "Kuwait",
            "KY": "Cayman Islands",
            "KZ": "Kazakhstan",
            "LA": "Lao Peoples Dem. Republic",
            "LB": "Lebanon",
            "LC": "St. Lucia",
            "LI": "Liechtenstein",
            "LK": "Sri Lanka",
            "LR": "Liberia",
            "LS": "Lesotho",
            "LT": "Lithuania",
            "LU": "Luxembourg",
            "LV": "Latvia",
            "LY": "Libya",
            "MA": "Morocco",
            "MC": "Monaco",
            "MD": "Moldova",
            "ME": "Montenegro",
            "MF": "St. Martin",
            "MG": "Madagascar",
            "MH": "Marshall Islands",
            "MK": "North Macedonia",
            "ML": "Mali",
            "MM": "Myanmar (Burma)",
            "MN": "Mongolia",
            "MO": "Macau",
            "MP": "Northern Mariana Islands",
            "MQ": "Martinique Fr Antilles",
            "MR": "Mauritania",
            "MS": "Montserrat",
            "MT": "Malta",
            "MU": "Mauritius",
            "MV": "Maldives",
            "MW": "Malawi",
            "MX": "Mexico",
            "MY": "Malaysia",
            "MZ": "Mozambique",
            "NA": "Namibia",
            "NC": "New Caledonia",
            "NE": "Niger",
            "NF": "Norfolk Island",
            "NG": "Nigeria",
            "NI": "Nicaragua",
            "NL": "Netherlands",
            "NO": "Norway",
            "NP": "Nepal",
            "NR": "Nauru",
            "NU": "Niue",
            "NZ": "New Zealand",
            "OM": "Oman",
            "PA": "Panama",
            "PE": "Peru",
            "PF": "French Polynesia",
            "PG": "Papua New Guinea",
            "PH": "Philippines",
            "PK": "Pakistan",
            "PL": "Poland",
            "PM": "St. Pierre and Miquelon",
            "PN": "Pitcairn Islands"
        };
        this.checkList = [
            {
                key: "legal_name",
                question: "Does your company have a legal name?",
                error: "Your business does not have a legal name"
            }, {
                key: "tax_id",
                question: "Does your company have a Federal Tax ID (EIN)?",
                error: "Your business does not have a Federal Tax ID (EIN)"
            }, {
                key: "company_email",
                question: "Do you have a company email?",
                error: "Your business does not have a company email"
            }, {
                key: "company_website",
                question: "Do you have a company website?",
                error: "Your business does not have a company website"
            }, {
                key: "opt_in_url",
                question: "Do you have an opt-in URL?",
                error: "Your business does not have a opt-in URL"
            }, {
                key: "privacy_policy",
                question: "Does your company have a Privacy Policy on the website?",
                error: "Your business does not have a Privacy Policy on the website"
            }, {
                key: "contact_form",
                question: "Does your company have an Opt-In/Contact Us form on the website?",
                error: "Your business does not have an Opt-In/Contact Us form on the website"
            }
        ];
        this.countries = Object.keys(countries).map(key => ({value: key, name: countries[key]})).sort((a, b) => a.name.localeCompare(b.name));
        this.handleCheckListChange = this.handleCheckListChange.bind(this);
        this.handleCheckListConfirm = this.handleCheckListConfirm.bind(this);
    }

    componentDidMount() {
        this.getProfile();
        numberServices.getIndustries()
            .then(res => this.setState({
                industries: res
            }))
            .catch(err => {
                toast.error(NotificationHelpers.INDUSTRIES_FETCH_ERROR);
            });
    }

    getProfile = () => {
        authServices.getProfile()
            .then(res => {
                const nameComponents = res.name.split(" ");
                const optInComponents = res.message_flow.split(" / Opt-in URL: ");
                this.setState({
                    profile: res,
                    industry: res.industry,
                    zip_code: res.zip_code,
                    street: res.street,
                    city: res.city,
                    state: res.state,
                    country: res.country || "US",
                    first_name: res.first_name || (nameComponents.length === 2 ? nameComponents[0] : ""),
                    last_name: res.last_name || (nameComponents.length === 2 ? nameComponents[1] : ""),
                    phone: res.phone,
                    sample_message_1: res.sample_message_1,
                    sample_message_2: res.sample_message_2,
                    website: res.website,
                    company: res.company,
                    company_email: res.company_email,
                    use_case: res.use_case,
                    ein: res.ein,
                    entity_type: res.entity_type,
                    stock_symbol: res.stock_symbol,
                    stock_exchange: res.stock_exchange,
                    message_flow: optInComponents[0],
                    campaign_description: res.campaign_description,
                    direct_lending: res.direct_lending ? "Yes" : "",
                    opt_in_url: optInComponents[1] || ""
                }, this.handleCheckZipCode);
            })
            .catch(err => {
                toast.error(NotificationHelpers.PROFILE_FETCH_ERROR);
            });
    }

    handleInputChange = (e) => {
        const target = e.target;
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.country === "US" && target && target.name === "zip_code") {
                this.handleCheckZipCode();
            }
        });
    };

    handleCheckZipCode = () => {
        if (this.state.zip_code.length !== 5) {
            return;
        }
        numberServices.checkZipCode(this.state.zip_code)
            .then(res => {
                this.setState({
                    city: res.city,
                    state: res.state
                });
            })
            .catch(err => {
                err.text().then(res => {
                    toast.error(JSON.parse(res).error);
                });
            });
    };

    handleCompleteProfile = () => {
        if (!this.state.opt_in_url) {
            toast.error("Opt-In URL is required.");
            return;
        }
        const profile = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone: this.state.phone,
            street: this.state.street,
            zip_code: this.state.zip_code,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            industry: this.state.industry,
            sample_message_1: this.state.sample_message_1,
            sample_message_2: this.state.sample_message_2,
            website: this.state.website,
            company: this.state.company,
            company_email: this.state.company_email,
            use_case: this.state.use_case,
            ein: this.state.ein,
            entity_type: this.state.entity_type,
            stock_symbol: this.state.stock_symbol,
            stock_exchange: this.state.stock_exchange,
            message_flow: this.state.message_flow + " / Opt-in URL: " + this.state.opt_in_url,
            campaign_description: this.state.campaign_description,
            direct_lending: !!this.state.direct_lending
        };
        this.setState({loading: true});
        numberServices.completeProfile(profile)
            .then(res => {
                window.location.href = "/app";
            }).catch(err => {
            err.text()
                .then(res => {
                    toast.error(JSON.parse(res).error);
                });
        }).finally(() => {
            this.setState({loading: false});
        });
    };

    handleCheckListChange = (i, v) =>{
        if(v.target.value === '1'){
            this.setState(prev=>({
                ...prev,
                checkConfirm: [...new Set([...prev.checkConfirm, i])],
                checkMissing: prev.checkMissing.filter(m=> m !== i).sort()
            }))
        }else{
            this.setState(prev=>({
                ...prev,
                checkMissing: [...new Set([...prev.checkMissing, i])].sort(),
                checkConfirm: prev.checkConfirm.filter(c=> c !== i)
            }))
        }
    }

    handleCheckListConfirm = (i, v) =>{
        this.setState({
            step: 1,
        })
    }

    render() {
        const {profile, step, checkMissing, checkConfirm} = this.state;
        const isProfileCompleted = profile && profile.brand_id && profile.onvoy_campaign_id;
        if (isProfileCompleted) {
            window.location.href = "/app";
            return;
        }
        const needPayment = profile && !profile.slybroadcast_customer && !profile.stripe_customer &&!profile.braintree_customer;
        return (
            <div>
                <NavbarFull/>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 offset-md-2 mt-4">
                            {!profile ?
                                <ReactLoading type="spin"
                                              color={"#000000"}
                                              className={"centered"}
                                /> :
                                (step === 0 ? <React.Fragment>
                                    <h2>Hello, {profile.name}</h2>
                                    <p>When sending text messages from a 10-digit number (<a
                                        href="https://slytext.com/blog/what-is-10dlc.html" target="_blank">10DLC</a>),
                                        campaigns must be submitted to the Campaign Registry in order to be vetted and
                                        approved for accuracy.
                                        You are required to submit this information, regardless of how many text
                                        messages you will be sending per month.
                                        Please make sure your business has all the required information below before
                                        moving forward.
                                    </p>
                                        <div className={"alert alert-danger"}>
                                            If you answer 'No' to any of the following questions, your submission will be
                                            rejected, and you will not be able to proceed to the next step. Please
                                            contact <a style={{whiteSpace: "nowrap"}}
                                                       href="mailto:support@slytext.com">support@slytext.com</a> with
                                            questions.
                                        </div>
                                        <div className="container text-left mt-4">
                                        {
                                                this.checkList && this.checkList.map((check, index) => (
                                                    <div className={"my-3 row justify-content-between"} key={check.key}>
                                                        <label htmlFor={check.key} className="field-label-2">
                                                            {index + 1}. {check.question} <span
                                                            className={"text-danger mx-1"}>*</span>
                                                        </label>
                                                        <span className={"d-flex align-items-center"}>
                                                        <p className={"mx-2 my-0"}>Yes</p>
                                                        <input className={"mr-3"} type="radio" name={check.key}
                                                               value={1}
                                                               onChange={(val) => this.handleCheckListChange(index, val)}/>
                                                        <p className={"mx-2 my-0"}>No</p>
                                                        <input type="radio" name={check.key} value={0}
                                                               onChange={(val) => this.handleCheckListChange(index, val)}/>
                                                    </span>

                                                    </div>
                                                ))
                                            }
                                            {
                                                checkMissing.length > 0 && <div className={"row text-center"}>
                                                    <div className={"col-12 my-1"}>
                                                        <div className={"text-danger"}><b>You cannot proceed for the following reason(s):</b></div>
                                                    </div>
                                                    {checkMissing.map(i=>(
                                                        <div className={"col-12 my-1"} key={`check_error_${i}`}>
                                                            <div className={"text-dark"}>{this.checkList[i] && this.checkList[i].error}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            <div className={"row mt-3"}>
                                                <div className={"col text-center"}>
                                                    <button
                                                        className={`btn ${checkMissing.length !== 0 || checkConfirm.length !== this.checkList.length ? "btn-dark" : "btn-primary"} mb-lg-5`}
                                                        disabled={checkMissing.length !== 0 || checkConfirm.length !== this.checkList.length}
                                                        onClick={this.handleCheckListConfirm}>
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <h2>Hello, {profile.name}</h2>
                                        <p>When sending text messages from a 10-digit number (<a
                                            href="https://slytext.com/blog/what-is-10dlc.html" target="_blank">10DLC</a>),
                                            campaigns must be submitted to the Campaign Registry in order to be vetted
                                            and approved for accuracy. The information below is required for submission,
                                            regardless of how many text messages you will be sending per month. When
                                            submitting the information below, it is important to be accurate. Any links
                                            or information that cannot be verified will result in campaign approval delays. Campaign approval can take up to 21 days to complete.</p>
                                    <p>A one time $15 vetting fee will apply in addition to the $12 monthly fee.</p>
                                    <p>Need help filling out this page? Click <a
                                        href="https://slytext.com/10-dlc-best-practices.html" target="_blank">here</a>.
                                    </p>
                                    {profile.rejected_reason && <div className="alert alert-danger">
                                        Your submission is rejected, please update the following information and
                                        resubmit:<br/> {profile.rejected_reason}
                                        {profile.rejected_reason.includes("update your payment method") && <>
                                            <br/><a href="#/subscription">Update Payment</a></>}
                                    </div>}
                                    {needPayment && <div className="alert alert-danger">You don't have a payment method on file.<br/><a href="#/subscription">Add a Card</a></div>}
                                        <div className="container text-left container mt-4">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="first_name" className="field-label-2">
                                                        First Name<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="first_name"
                                                           placeholder="First Name"
                                                           id="first_name"
                                                           value={this.state.first_name}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="last_name" className="field-label-2">
                                                        Last Name<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="last_name"
                                                           placeholder="Last Name"
                                                           id="last_name"
                                                           value={this.state.last_name}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="industry" className="field-label-2">
                                                        Industry<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <select id="industry"
                                                            required
                                                            onChange={this.handleInputChange}
                                                            name="industry"
                                                            className="select-field w-select"
                                                            value={this.state.industry}
                                                    >
                                                        <option>Select your industry</option>
                                                        {this.state.industries.map(ind => <option key={ind}>{ind}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="use_case" className="field-label-2">
                                                        Use Case<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <select id="use_case"
                                                            required
                                                            onChange={this.handleInputChange}
                                                            name="use_case"
                                                            className="select-field w-select"
                                                            value={this.state.use_case}
                                                    >
                                                        <option>Select your use case</option>
                                                        <option value="ACCOUNT_NOTIFICATION">Account Notification</option>
                                                        <option value="CUSTOMER_CARE">Customer Care</option>
                                                        <option value="HIGHER_EDUCATION">Higher Education</option>
                                                        <option value="MARKETING">Marketing</option>
                                                        <option value="POLLING_VOTING">Polling and Voting</option>
                                                        <option value="PUBLIC_SERVICE_ANNOUNCEMENT">Public Service Announcement</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="entity_type" className="field-label-2">
                                                        Entity Type<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <select id="entity_type"
                                                            required
                                                            onChange={this.handleInputChange}
                                                            name="entity_type"
                                                            className="select-field w-select"
                                                            value={this.state.entity_type}
                                                    >
                                                        <option>Select entity type</option>
                                                        <option value="PRIVATE_PROFIT">Private company</option>
                                                        <option value="PUBLIC_PROFIT">Publicly traded company</option>
                                                        <option value="NON_PROFIT">Non-Profit organization</option>
                                                        <option value="GOVERNMENT">Government corporation</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="company" className="field-label-2">
                                                        Company Name<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="company"
                                                           placeholder="Company Name"
                                                           id="company"
                                                           value={this.state.company}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="ein" className="field-label-2">
                                                        Federal Tax ID (EIN)<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="ein"
                                                           placeholder="EIN"
                                                           id="ein"
                                                           value={this.state.ein}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="phone" className="field-label-2">
                                                        Phone Number<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="11"
                                                           name="phone"
                                                           placeholder="Support phone number associated with company domain"
                                                           id="phone"
                                                           value={this.state.phone}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="company_email" className="field-label-2">
                                                        Company Support Email<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="company_email"
                                                           placeholder="Support email address associated with company domain"
                                                           id="company_email"
                                                           value={this.state.company_email}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="direct_lending" className="field-label-2">
                                                        Direct Lending or Loan Arrangement related?
                                                    </label>
                                                    <select id="direct_lending"
                                                            onChange={this.handleInputChange}
                                                            name="direct_lending"
                                                            className="select-field w-select"
                                                            value={this.state.direct_lending}
                                                    >
                                                        <option value="">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="website" className="field-label-2">
                                                        Online Presence<span className={"text-danger"}>*</span> (Website URL advertising your service)
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           name="website"
                                                           placeholder="Company Website"
                                                           id="website"
                                                           value={this.state.website}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                            {this.state.company && this.state.company_email && <div className="row">
                                                <div className="col">
                                                    <label htmlFor="sample_message_1" className="field-label-2">
                                                        Help Message (If a
                                                        recipient replies HELP, the following message will be sent)
                                                    </label>
                                                    <p className="text-field mb-3">
                                                        {this.state.company}: For help, email {this.state.company_email}.
                                                        To opt-out, reply STOP
                                                    </p>
                                                </div>
                                            </div>}
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="campaign_description" className="field-label-2">
                                                        Campaign Description<span
                                                        className={"text-danger"}>*</span> (A detailed description of
                                                        how you plan to use the service)
                                                    </label>
                                                    <div className="text-field text-secondary mb-1">
                                                        Example: Text messaging will be used for customer care and
                                                        marketing promotions.
                                                    </div>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           minLength="40"
                                                           maxLength="256"
                                                           name="campaign_description"
                                                           placeholder="Campaign Description"
                                                           id="campaign_description"
                                                           value={this.state.campaign_description}
                                                           autoComplete="new-password"
                                                           onChange={this.handleInputChange}
                                                           required
                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="message_flow" className="field-label-2">
                                                        Opt-In Info<span
                                                        className={"text-danger"}>*</span> (This field should describe
                                                        how a consumer opts-in to the campaign, therefore giving consent
                                                        to the sender to receive their messages)
                                                    </label>
                                                    <div className="text-field text-secondary mb-1">
                                                        Example: The user fills out a form on the website where they can
                                                        consent to receive notification messages. There is also a box
                                                        they can check to receive product updates and/or marketing
                                                        messages. The messaging disclosure is displayed to users by this
                                                        form.
                                                    </div>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           minLength="40"
                                                           maxLength="256"
                                                           name="message_flow"
                                                           placeholder="Opt-In Info"
                                                           id="message_flow"
                                                           value={this.state.message_flow}
                                                           autoComplete="new-password"
                                                           onChange={this.handleInputChange}
                                                           required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="opt_in_url" className="field-label-2">
                                                        Opt-In URL<span className={"text-danger"}>*</span> (URL where customers opt-in. Check <a href="https://www.mytlcstudent.com/inquiry" target="_blank"><u>here</u></a> to view sample opt-in language)
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           name="opt_in_url"
                                                           placeholder="Opt-In URL"
                                                           id="opt_in_url"
                                                           value={this.state.opt_in_url}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                            {this.state.entity_type === "PUBLIC_PROFIT" && <div className="row">
                                                <div className="col">
                                                    <label htmlFor="stock_symbol" className="field-label-2">
                                                        Stock Symbol<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           maxLength="256"
                                                           name="stock_symbol"
                                                           placeholder="Stock Symbol"
                                                           id="stock_symbol"
                                                           value={this.state.stock_symbol}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="stock_exchange" className="field-label-2">
                                                        Stock Exchange<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           maxLength="256"
                                                           name="stock_exchange"
                                                           placeholder="Stock Exchange"
                                                           id="stock_exchange"
                                                           value={this.state.stock_exchange}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>}
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="sample_message_1" className="field-label-2">
                                                        Sample Messages<span className={"text-danger"}>*</span> (For compliance purposes, please provide two sample messages that reflect the content that the user will receive. Messages must contain <b>Company name</b> and the opt-out phrase "<b>Reply stop to opt-out.</b>")
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="sample_message_1"
                                                           placeholder="Sample message 1"
                                                           id="sample_message_1"
                                                           value={this.state.sample_message_1}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="sample_message_2"
                                                           placeholder="Sample message 2"
                                                           id="sample_message_2"
                                                           value={this.state.sample_message_2}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="country" className="field-label-2">
                                                        Country<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <select id="country"
                                                            required
                                                            onChange={this.handleInputChange}
                                                            name="country"
                                                            className="select-field w-select"
                                                            value={this.state.country}
                                                    >
                                                        <option>Select your country</option>
                                                        {this.countries.map(c => <option key={c.value} value={c.value}>{c.name}</option>)}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="zip_code" className="field-label-2">
                                                        Zip Code<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength={this.state.country === "US" ? 5 : 12}
                                                           name="zip_code"
                                                           placeholder="Zip code"
                                                           id="zip_code"
                                                           value={this.state.zip_code}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="city" className="field-label-2">
                                                        City<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           maxLength="256"
                                                           name="city"
                                                           placeholder="City"
                                                           id="city"
                                                           value={this.state.city}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="state" className="field-label-2">
                                                        State/Province<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           maxLength="256"
                                                           name="state"
                                                           placeholder="State/Province"
                                                           id="state"
                                                           value={this.state.state}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="street" className="field-label-2">
                                                        Company Street Address<span className={"text-danger"}>*</span>
                                                    </label>
                                                    <input type="text"
                                                           className="text-field w-input"
                                                           autoFocus={true}
                                                           maxLength="256"
                                                           name="street"
                                                           placeholder="Company Street Address"
                                                           id="street"
                                                           value={this.state.street}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mb-lg-5"
                                                disabled={this.state.loading}
                                                onClick={this.handleCompleteProfile}>Confirm
                                        </button>
                                </React.Fragment>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
