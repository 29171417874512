/* eslint-disable */
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import {setTime} from "../../helper";
import "../../Styles/conversation-list.css";

export default function ConversationComponent(props) {
    return (
      <div className={props.conversation.has_unread?"container conversation-list-item p-0 unread-message":
                                                  "container conversation-list-item p-0"}>
                    <div className="row p-2">
                        <div className="col-3">
                            <img className=" round"
                                 src={props.conversation.group_id===0?
                                        props.target?"https://slytext.com"+props.target.avatar:"https://slytext.com/api/v2/avatars/contact/3575/10978756/thumb?1559331901337":
                                     props.target?"https://slytext.com"+props.target.avatar:"http://www.iconarchive.com/download/i104058/blackvariant/button-ui-system-folders-alt/Group.ico"}
                                 alt=""/>
                        </div>
                        <div className="col-9 bottom-bordered row p-0">
                            <div className="col-12 text-left">
                                <div className="row">
                                    <div className="col-8 title">
                                        {props.conversation.name}
                                        <span className={props.conversation.group_id===0?"hidden":""}>
                                          <i className="fa fa-bullhorn pl-2 text-muted"></i>
                                        </span>
                                        <span className={props.conversation.starred?"":"hidden"}>
                                          <i className="fa fa-star pl-2 text-warning"></i>
                                        </span>
                                    </div>
                                    <div className="col-4 text-right time-stamp p-0">
                                        {setTime(props.conversation.timestamp)}
                                    </div>
                                    <div className="col-12 message-text">
                                        {props.conversation.message}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </div>
    )
}

