/* eslint-disable */
import React from 'react'
import NavbarFull from './NavbarFull';

export default function VerifyNotificationPage() {
    return (
        <div>
            <NavbarFull></NavbarFull>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-6 col-sm-12 offset-md-3 mt-4">
                        <h2>Verify your email address</h2>
                        <p className="text-muted">We have sent a verification link to your email address.
                            Click the link in the email to complete email verification.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
